import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {
    MdOutlineDriveFileRenameOutline,
    MdOutlineCasino,
    MdOutlineRemoveCircleOutline,
} from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { IoIosAdd } from "react-icons/io";
import { RiLoader4Line } from "react-icons/ri";
import { UserInfoState } from "../../context/UserInformationContext";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import { FaAngleDown } from "react-icons/fa6";
import DropDown from "../../components/Common/DropDown";
import BrandSettings from "../../components/Profile/BrandSettings";
import ProfileInformation from "../../components/Profile/ProfileInformation";
import CasinoTokenSetting from "../../components/Profile/CasinoTokenSetting";

const DashBoard = () => {
    const { getUserInfo } = UserInfoState();
    const [userInfo, setUserInfo] = useState();
    const [casinoInfo, setCasinoInfo] = useState([]);
    const [flag, setFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [currentCasino, setCurrentCasino] = useState([]);
    const [getUserLoading, setGetUserLoading] = useState(true);
    const [activeAccordion, setActiveAccordion] = useState();
    const [currencies, setCurrencies] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isError, setIsError] = useState(false);

    const formSubmit = (e) => {
        e.preventDefault();
        if (isLoading) return;

        setIsLoading(true);
        var first_name = e.target.elements["first_name"]?.value;
        var last_name = e.target.elements["last_name"]?.value;
        var password = e.target.elements["password"]?.value;
        var confirmPassword = e.target.elements["confirmPassword"]?.value;

        var error = 0;
        setErrorMessage("");
        if (password !== "" || confirmPassword !== "") {
            if (password !== confirmPassword) {
                setErrorMessage("Password and Confirm Password does not match.");
                e.target.elements["confirmPassword"].focus();
                setIsLoading(false);
                error = 1;
            } else {
                error = 0;
            }
        }

        if (error === 0) {
            const data = {
                user_id: JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
                author_id: "user_" + JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
                first_name: first_name,
                last_name: last_name,
                password: password,
                casinoData: JSON.stringify(casinoInfo),
                token: JSON.parse(localStorage.getItem("user-authorization-token"))?.token,
            };
            axios({
                url: `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/updateuserinfo.php`,
                method: "POST",
                data: data,
                mode: "no-cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "POST",
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (res) => {
                    setIsError(false);
                    if (res?.data === 1) {
                        getUserInfo(
                            JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
                            JSON.parse(localStorage.getItem("user-authorization-token"))?.token,
                            'login'
                        );
                        toast.success("Changes Saved.", { theme: "dark", autoClose: 1500 });
                    } else {
                        toast.error(res?.data, { theme: "dark", autoClose: 1500 });
                    }
                })
                .catch((err) => {
                    setIsError(true);
                    toast.error(err, { theme: "dark", autoClose: 1500 });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const updateBrandsHandler = (brands) => {
        setBrands(brands);
    };

    const updateCasinosHandler = (casinos) => {
        setCasinoInfo(casinos);
    }

    const brandIdentifier = (casino_brand_id) => {
        return brands.filter(brand => brand?.brand_id === casino_brand_id);
    }

    // personal info onchange
    const handleChangeData = (evt) => {
        setIsDisabled(false);
        const value = evt.target.value;
        setUserInfo({
            ...userInfo,
            [evt.target.name]: value,
        });
    };

    // // casino setting onchange
    // const handleChange = (i, e) => {
    //     setIsDisabled(false);
    //     let newFormValues = [...casinoInfo];
    //     newFormValues[i][e.target.name] = e.target.value;
    //     setCasinoInfo(newFormValues);
    // };

    // const addFormFields = () => {
    //     setCasinoInfo([
    //         ...casinoInfo,
    //         {
    //             casino_name: "",
    //             casino_url: "",
    //             casino_token: "",
    //             casino_key: "",
    //             tax_rate: "",
    //             royalties: "",
    //             invoice_currency: "",
    //             bonus_deduction: "",
    //             bill_to: "",
    //         },
    //     ]);
    // };

    // const removeFormFields = (i, element) => {
    //     const confirmation = window.confirm(
    //         `Are you sure you want to delete ${element?.casino_name} casino?`
    //     );
    //     if (confirmation) {
    //         setIsDisabled(false);
    //         let newFormValues = [...casinoInfo];
    //         newFormValues.splice(i, 1);
    //         setCasinoInfo(newFormValues);
    //         setFlag(!flag);
    //     }
    // };

    useEffect(() => {
        setGetUserLoading(true);
        axios
            .post(
                "https://admin.playerreporting.com/api/userinfomation.php",
                {
                    userid: JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
                    token: JSON.parse(localStorage.getItem("user-authorization-token"))?.token
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "POST",
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                if (response?.data?.status) {
                    if (response?.status === 200) {
                        setCasinoInfo(response.data?.casino_information || []);
                        setBrands(response.data?.casino_brands || [])
                        setUserInfo(response?.data);
                    } else {
                        toast.error(response?.data?.message, { theme: "dark", autoClose: 1500 });
                    }
                } else {
                    toast.error(response?.data?.error, { theme: "dark" });
                    localStorage?.removeItem("user-authorization-token");
                    localStorage?.removeItem("currentCasino");
                    localStorage?.removeItem("userInformation");
                }
            })
            .catch((error) => {
                toast.error(error?.message, { theme: "dark", autoClose: 1500 });
            })
            .finally(() => {
                setGetUserLoading(false);
            });
    }, []);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/currencies/list?token=${JSON.parse(localStorage.getItem("currentCasino"))?.casino_token
                }`
            )
            .then((response) => {
                if (response?.data?.status === 200) {
                    setCurrencies([{ currency_abrv: "All" }, ...response?.data?.data]);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(error.response?.data?.message, {
                        theme: "dark",
                        autoClose: 1500,
                    });
                } else {
                    toast.error(error.message, { theme: "dark", autoClose: 1500 });
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <section className="dashboard_sec">
                <div className="table_loader_wp" style={{ minHeight: "70px" }}>
                    {getUserLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <ProfileInformation userInfo={userInfo} />
                            <BrandSettings brands={brands} currencies={currencies} updateBrands={updateBrandsHandler} isError={isError} />
                            <CasinoTokenSetting casinoInfo={casinoInfo} brands={brands} updateCasinos={setCasinoInfo} currencies={currencies} isError={isError} />
                        </>
                    )}

                </div>
            </section>
        </>
    );
};

export default DashBoard;
