import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import FavIcon from "../../assets/images/favicon.ico";
import { SideBarData } from "./SideBarData";
import { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { UserInfoState } from "../../context/UserInformationContext";

const SideBar = ({ sideBar, header_menu_button }) => {
    const [width, setWidth] = useState();
    const location = useLocation();
    const [dropDown, setDropdown] = useState(false);
    const { getUserInfo, accessiblePages, isLoading } = UserInfoState();

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [width]);

    useEffect(() => {
        getUserInfo(
            JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
            JSON.parse(localStorage.getItem("user-authorization-token"))?.token,
            'sidebar'
        );
    }, []);

    useEffect(() => {
        if (
            location.pathname === "/finance" ||
            location.pathname === "/financial-reports" ||
            location.pathname === "/game-reports" ||
            location.pathname === "/user-permission"
        ) {
            setDropdown(true);
        } else {
            setDropdown(false);
        }
    }, [location.pathname]);

    return (
        <>
            {!sideBar && <div className="sidebar_overlay" onClick={header_menu_button}></div>}
            <aside className="sidebar">
                <div className="sidebar_top">
                    <Link to="/" title="Player Reporting" className="site_logo">
                        <img src={sideBar ? FavIcon : Logo} alt="Logo" />
                    </Link>
                </div>

                <nav className="sidebar_menulist">
                    {
                        <ul>
                            {isLoading &&
                                SideBarData.map((i, index) => {
                                    return (
                                        <li key={index} className="menu_item_loader">
                                            <span></span>
                                        </li>
                                    );
                                })}
                            {!isLoading &&
                                SideBarData.map((data, index) => {
                                    if (data.hasOwnProperty("page_name")) {
                                        if (!accessiblePages?.includes(data.page_name)) {
                                            return;
                                        }
                                    }
                                    if (data?.title === "Admin Reporting") {
                                        const exist = data.child?.find((item) =>
                                            accessiblePages?.includes(item?.page_name)
                                        );
                                        if (!exist) return;
                                    }
                                    return (
                                        <li
                                            li
                                            key={index}
                                            className={`${data?.child ? "submenu" : ""} ${!sideBar && dropDown && data?.child ? "active_submenu" : ""
                                                }`}>
                                            <NavLink
                                                className={location.pathname === data.path ? "isActive" : ""}
                                                to={data.path}
                                                title={data.title}
                                                onClick={() =>
                                                    (!data?.child && width <= 1199 && header_menu_button()) ||
                                                    (data?.child && setDropdown((prev) => !prev))
                                                }>
                                                <span>
                                                    {data.icon}
                                                    {!sideBar && (
                                                        <>
                                                            <div>{!sideBar && data.title}</div>{" "}
                                                            {data?.child && (
                                                                <FaAngleDown className="dropdown_arrow" />
                                                            )}
                                                        </>
                                                    )}
                                                </span>
                                            </NavLink>

                                            {data.admin && data?.child && (
                                                <div className="submenu_wrapper">
                                                    <div>
                                                        <ul>
                                                            {data?.child.map((childData, index) => {
                                                                if (childData.hasOwnProperty("page_name")) {
                                                                    if (
                                                                        !accessiblePages?.includes(
                                                                            childData.page_name
                                                                        )
                                                                    ) {
                                                                        return;
                                                                    }
                                                                }
                                                                return (
                                                                    <li key={`submenu_${index}`}>
                                                                        <NavLink
                                                                            className={
                                                                                location.pathname ===
                                                                                    childData.path
                                                                                    ? "isActive"
                                                                                    : ""
                                                                            }
                                                                            to={childData.path}
                                                                            title={childData.title}
                                                                            onClick={() =>
                                                                                width <= 1199 &&
                                                                                header_menu_button()
                                                                            }>
                                                                            <span>
                                                                                {childData.icon}{" "}
                                                                                <div>{childData.title}</div>
                                                                            </span>
                                                                        </NavLink>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                        </ul>
                    }
                </nav>
            </aside>
        </>
    );
};

export default SideBar;
