import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import Logo from "../../assets/images/logo.png";
import CasinoName from "./CasinoName";
import UserProfile from "./UserProfile";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

const Header = ({ header_menu_button, mobileHeader, sideBar, sideBarButton }) => {
	const [pageName, setPageName] = useState();
	const [playerToggle, setPlayerToggle] = useState(false);
	const [activeDropdown, setActiveDropdown] = useState("");
	const [width, setWidth] = useState();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		// if (location.pathname === "/login" && localStorage.getItem("user-authorization-token")) {
		//     navigate('/');
		// }
		if (location.pathname === "/player-reports") {
			setPageName("Player Reports");
		} else if (location.pathname === "/casino-settings") {
			setPageName("Casino Settings");
		} else if (location.pathname === "/2fa-settings") {
			setPageName("2FA Settings");
		} else if (location.pathname === "/casino") {
			setPageName("Main Dashboard");
		} else if (location.pathname === "/casino-game-reports") {
			setPageName("Casino Game Reports");
		} else if (location.pathname === "/casino-game-rounds") {
			setPageName("Casino Game Rounds");
		} else if (location.pathname === "/financial-statements") {
			setPageName("Financial Statements");
		} else if (location.pathname === "/invoices") {
			setPageName("Invoices");
		} else if (location.pathname === "/finance") {
			setPageName("Finance");
		} else if (location.pathname === "/financial-reports") {
			setPageName("Financial Reports");
		} else if (location.pathname === "/player") {
			setPageName("Player");
		} else if (location.pathname === "/game-reports") {
			setPageName("Game Reports");
		} else if (location.pathname === "/user-permission") {
			setPageName("User Permission");
		} else {
			setPageName("Home - Dashboard");
		}
	}, [location.pathname]);

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const playerToggleButton = () => {
		setPlayerToggle(!playerToggle);
	};
	useEffect(() => {
		document.title = `${pageName} - Casino and Sports Betting Dashboard`;
	}, [pageName]);

	return (
		<>
			<button className="sidebar_collapse_button for_des">
				{sideBar ? (
					<MdOutlineKeyboardArrowRight
						size={22}
						onClick={sideBarButton}
					/>
				) : (
					<MdOutlineKeyboardArrowLeft
						size={22}
						onClick={sideBarButton}
					/>
				)}
			</button>

			<button
				className="sidebar_collapse_button for_mob"
				onClick={header_menu_button}
			>
				<AiOutlineClose size={25} />
			</button>

			<header className="site_header">
				<div className="top_header for_mob">
					<Link
						to="/"
						title="Logo"
						className="site_logo"
					>
						<img
							width={190}
							height={60}
							src={Logo}
							alt="Logo"
						/>
					</Link>

					<button
						type="button"
						className="player_toggle menu_toggle"
						onClick={playerToggleButton}
					>
						<FaRegUserCircle size={25} />
					</button>

					<button
						type="button"
						className="menu_toggle"
						onClick={header_menu_button}
					>
						<AiOutlineMenu size={25} />
					</button>
				</div>

				<div className="main_header_content">
					<h5 className="my_0">{pageName}</h5>

					<div className={`header_right ${playerToggle ? "player_open" : ""}`}>
						<ul>
							<li>
								<CasinoName
									activeDropdown={activeDropdown}
									setActiveDropdown={setActiveDropdown} // casino
									width={width}
								/>
							</li>

							<li>
								<UserProfile
									activeDropdown={activeDropdown}
									setActiveDropdown={setActiveDropdown} // profile
									width={width}
								/>
							</li>
						</ul>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
