import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../Loader";

const Verify2fa = ({ twoFaSetting, setTwoFaSetting }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [code, setCode] = useState("");
	const [codeError, setCodeError] = useState("");

	const verify2Fa = () => {
		setCodeError("");
		if (!code) {
			setCodeError("Please enter code");
			return;
		} else if (code.length !== 6) {
			setCodeError("Please enter six digit valid code");
			return;
		}
		const userId = JSON.parse(localStorage.getItem("user-authorization-token"))?.userid;
		setIsLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/2fa/verify-2fa.php`,
				{ user_id: userId, code },
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((response) => {
				if (response.data?.status) {
					setCode("");
					setTwoFaSetting((prev) => ({ ...prev, verifiedOtp: true, isDisplayVerifyOtp: false }));
					toast.success(response.data?.message, { theme: "dark", autoClose: 7000 });
				} else {
					toast.error(response.data?.message, { theme: "dark", autoClose: 7000 });
				}
			})
			.catch((error) => {
				toast.error(error?.message, { theme: "dark", autoClose: 7000 });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<div className="verify_otp_wrapper d-flex flex-column align-items-center">
				<div className="verify_otp">
					<input
						style={{ textAlign: "center", letterSpacing: "3px" }}
						type="number"
						className="form_input"
						onInput={(e) => {
							if (e.target.value) {
								const decimals = e.target.value.length;
								if (decimals > 6) e.target.value = e.target.value.slice(0, 6);
							}
						}}
						onChange={(e) => setCode(e.target.value)}
						value={code}
						placeholder="123456"
					/>
					<button
						type="button"
						className="sec_btn"
						onClick={() => verify2Fa()}
						disabled={isLoading}
					>
						Verify OTP {isLoading && <Loader size={22} />}
					</button>
					{twoFaSetting.isDisplayCancelButton && (
						<button
							type="button"
							className="sec_btn"
							onClick={() => setTwoFaSetting((prev) => ({ ...prev, isDisplayVerifyOtp: false }))}
						>
							{/* <IoMdClose />  */} X
						</button>
					)}
				</div>
				{codeError && <p className="verify_otp_message">{codeError}</p>}
			</div>
		</>
	);
};

export default Verify2fa;
