import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { GiCreditsCurrency } from "react-icons/gi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import MonthlyGameTypeSummaries from "./MonthlyGameTypeSummaries";
import DropDown from "../../Common/DropDown";

const defaultGameType = "All";

const FinancialGameTypeReports = ({ startDate, endDate, currency, refreshToggle, width }) => {
    const [allGameTypes, setAllGameTypes] = useState([]);
    const [selectedGameType, setSelectedGameType] = useState(defaultGameType);
    const [gameDropDown, setGameDropdown] = useState(false);

    /**
     * Games Type List
     */
    useEffect(() => {
        const casino = JSON.parse(localStorage?.getItem("currentCasino"));
        axios
            .get(`https://api.playerreporting.com/games/gametypes/list?token=${casino?.casino_token}`)
            .then((response) => {
                setAllGameTypes([defaultGameType, ...response.data?.data]);
            });
    }, []);

    return (
        <>
            <Row>
                <Col lg={9}>
                    <div>
                        <h5 className="h5_title">Monthly Game Summaries with Brands</h5>
                    </div>
                </Col>
                <Col lg={3}>
                    <>
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                    <GiCreditsCurrency size={20} />
                                </div>
                                Select Game Type
                            </label>
                            <DropDown
                                placeholder="Select Game Type"
                                options={allGameTypes}
                                dataKey=""
                                isSelected={selectedGameType}
                                activeOption={selectedGameType}
                                activeOptionId={selectedGameType}
                                setOption={(data, index) => {
                                    setSelectedGameType(data);
                                }}
                            />
                            {/* <div
                                className={`dropdown_wp currency_dropdown ${
                                    gameDropDown ? "active_dropdown" : ""
                                }`}>
                                <button
                                    className="sec_btn dropdown_btn transparent_btn"
                                    onClick={() => width <= 991 && setGameDropdown((prev) => !prev)}
                                    onMouseEnter={() => width >= 991 && setGameDropdown(true)}
                                    onMouseLeave={() => width >= 991 && setGameDropdown(false)}>
                                    {selectedGameType}
                                    <span className="dropdown_icon">
                                        <MdOutlineKeyboardArrowDown size={22} />
                                    </span>
                                </button>

                                <ul className="dropdown_list">
                                    {allGameTypes?.map((gameType) => (
                                        <li
                                            key={gameType}
                                            onClick={() => {
                                                setSelectedGameType(gameType);
                                            }}>
                                            {gameType}
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    </>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <div className="my_20">
                        <MonthlyGameTypeSummaries
                            selectedGameType={selectedGameType}
                            startDate={startDate}
                            endDate={endDate}
                            currency={currency}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default FinancialGameTypeReports;
