import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { Row, Col } from "react-bootstrap";
import { formatNum } from "../../utils";
import axios from "axios";

const Revenue = ({ currency,startDate,endDate }) => {
    const [loading, setLoading] = useState(false);
    const [revenueData, setRevenueData] = useState([]);
    const [responseCurrency, setResponseCurrency] = useState();
    const [errorMessage, setErrorMessage] = useState("");

    // render Revenue By Period
    const getRevenueData = async () => {
        setLoading(true);
        const casino = JSON.parse(localStorage.getItem("currentCasino"));

        await axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/reports/transactions/dashboard/revenue?token=${
                    casino.casino_token
                }&casino=${casino.casino_key}&currency=${currency === "All" ? "" : currency}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    setRevenueData(response.data.response);
                    setResponseCurrency(response.data?.currency);
                } else {
                    setErrorMessage(response?.data?.message);
                }
            })
            .catch((error) => {
                if (error.response) {
                    setErrorMessage(error.response?.data?.message);
                } else {
                    setErrorMessage(error.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getRevenueData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency,startDate,endDate]);

    return (
        <>
            <section className="revenue_sec pt_60">
                <Row>
                    <Col lg={12}>
                        <div className="sec_wp mb_30">
                            {/* <h2 className="h2_title">revenue by period</h2> */}
                            <div className="divider"></div>
                        </div>
                    </Col>
                </Row>

                <div className="revenue_box">
                    <div className="table_loader_wp" style={{ minHeight: "70px" }}>
                        <Row>
                            {errorMessage && (
                                <p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
                            )}
                            {revenueData.map((data, index) => {
                                const { tag, ggr, totalAmount } = data;
                                return (
                                    <Col lg={3} sm={6} key={index}>
                                        <div className="revenue_box_content text-center">
                                            <h5 className="h5_title">{tag}</h5>

                                            <div className="revenue_box_usd">{responseCurrency}</div>

                                            <h4 className="h4_title">GGR {`${formatNum.format(ggr)}`}</h4>
                                            <p className="m-0">
                                                {totalAmount
                                                    ? `Total Amt. ${formatNum.format(totalAmount)}`
                                                    : `0.00`}
                                            </p>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                        {loading && <Loader />}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Revenue;
