import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const UserInfoContext = createContext();

const UserProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState();
    const [accessiblePages, setAccessiblePages] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("userInformation")) {
            setUserInfo(JSON.parse(localStorage.getItem("userInformation")));
        }
    }, []);

    const getUserInfo = async (id, token = '', action = '') => {

        // alert(action);
        // if (token === '') {
        // if (action !== 'login') {
        //     id = localStorage.getItem("user-authorization-token")?.userid;
        //     token = localStorage.getItem("user-authorization-token")?.token;
        // }
        // if (id && token) {
        //     // navigate("/");
        // }
        // return null;
        // }
        // }

        setIsLoading(true);
        await axios
            .post(
                `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/userinfomation.php`,
                { userid: id, token: token },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "POST",
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((result) => {
                if (result?.data?.status) {
                    const data = {
                        ...result?.data,
                        userId: id,
                    };
                    setAccessiblePages(data?.accessible_pages);
                    setUserInfo(data);
                    localStorage.setItem("userInformation", JSON.stringify(data));
                    if (localStorage?.getItem("currentCasino") === null) {
                        localStorage.setItem("currentCasino", JSON?.stringify(data?.casino_information[0]));
                    }
                } else {
                    toast.error(result?.data?.error, { theme: "dark" });
                    localStorage?.removeItem("user-authorization-token");
                    localStorage?.removeItem("currentCasino");
                    localStorage?.removeItem("userInformation");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <UserInfoContext.Provider
            value={{
                getUserInfo,
                userInfo,
                accessiblePages,
                isLoading,
            }}>
            {children}
        </UserInfoContext.Provider>
    );
};

export const UserInfoState = () => {
    return useContext(UserInfoContext);
};

export default UserProvider;
