import PlayerTable from "./PlayerTable";

const CasinoGameRounds = () => {
    return (
        <>
            <PlayerTable />
        </>
    );
};

export default CasinoGameRounds;
