import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../../Loader";
import { Col, Row } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GiCreditsCurrency } from "react-icons/gi";
import CurrenciesDropdown from "../../Common/CurrenciesDropdown";
import DropDown from "../../Common/DropDown";

const defaultGame = { id: 0, game: "All" };
const filterByList = ["Days", "Weeks", "Months"];

const GamesBetsTransactionsChart = ({ startDate, endDate, currencies, refreshToggle, width }) => {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [allGames, setAllGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState(defaultGame);
    const [filterBy, setFilterBy] = useState(filterByList[0]);
    const [currency, setCurrency] = useState("All");
    const [numberOfBets, setNumberOfBets] = useState(-1);
    const [filterDropDown, setFilterDropdown] = useState(false);
    const [gameDropDown, setGameDropdown] = useState(false);

    useEffect(() => {
        const casino = JSON.parse(localStorage?.getItem("currentCasino"));
        axios
            .get(`https://api.playerreporting.com/games/list?token=${casino?.casino_token}`)
            .then((response) => {
                setAllGames([defaultGame, ...response.data?.data]);
            });
    }, []);

    const getData = () => {
        setErrorMessage("");
        const casino = JSON.parse(localStorage?.getItem("currentCasino"));
        const game = !selectedGame ? "" : selectedGame?.id === 0 ? "" : selectedGame?.id;

        setLoading(true);

        axios
            .get(
                `https://api.playerreporting.com/games/reports/game-bets-transactions?token=${casino?.casino_token
                }&casino=${casino?.casino_key}&currency=${currency === "All" ? "" : currency
                }&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&game=${game}&action=game&filterBy=${filterBy}`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    let reportByDays = response.data?.data.reportByDays;
                    if (reportByDays?.length === 0) {
                        setErrorMessage("There are no records to display");
                    }
                    if (reportByDays.length) {
                        reportByDays[0].currency = response.data.data.currency;
                    }
                    setChartData(reportByDays);
                    setNumberOfBets(response?.data?.data?.numberOfBets);
                } else {
                    setChartData([]);
                    setErrorMessage(response?.data?.message);
                    setNumberOfBets(-1);
                }
            })
            .catch((error) => {
                setChartData([]);
                setNumberOfBets(-1);
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGame, filterBy, currency, refreshToggle]);

    const playerChartoption = {
        options: {
            chart: {
                type: "bar",
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    colors: {
                        ranges: [
                            { from: -100, to: -46, color: "#F15B46" },
                            { from: -45, to: 0, color: "#FEB019" },
                        ],
                    },
                    columnWidth: "80%",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: [1, 1, 4],
            },
            yaxis: {
                padding: {
                    left: 0,
                    right: 0,
                },
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    // offsetX: -12,
                    show: true,
                    color: "#008FFB",
                },
                title: {
                    text: "Bets Amount",
                    style: {
                        color: "#008FFB",
                    },
                },
                labels: {
                    formatter: function (y) {
                        return y;
                    },
                    style: {
                        colors: "#008FFB",
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
            xaxis: {
                // type: 'datetime',
                labels: {
                    rotate: -90,
                    style: {
                        colors: "#008FFB",
                    },
                },
            },
            tooltip: {
                theme: "dark",
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const firstData = w.globals.initialSeries[seriesIndex].data[0];

                    return `<div class='game_bets_tooltip'>
						<p>${data.x}</p>
						<p class='game_bets_tooltip_total_bets'>Number of Debits: <b>${data.y}</b></p>
						<p class='game_bets_tooltip_total_bets'>Debits: <b>${data.debit?.toFixed(2)} ${firstData?.currency} </b></p>
						<p class='game_bets_tooltip_total_bets'>Credits: <b>${data.credit?.toFixed(2)} ${firstData?.currency}</b></p>
						<p class='game_bets_tooltip_total_bets'>Total Bets: <b>${data.allAmount?.toFixed(2)} ${firstData?.currency
                        }</b></p>
					</div >`;
                },
            },
            legend: {
                horizontalAlign: "left",
                offsetX: 20,
                labels: {
                    colors: "#fff",
                },
                markers: {
                    offsetX: -3,
                    offsetY: 2,
                },
                itemMargin: {
                    horizontal: 10,
                },
            },
            grid: {
                padding: {
                    left: 0,
                    right: 0,
                },

                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        },
        series: [
            {
                name: "Bets Amount",
                data: chartData,
            },
        ],
    };

    return (
        <>
            <div className="finance_heapchart mb_30">
                <Row className="align-items-center">
                    <Col lg={3}>
                        <h5 className="h5_title">Transactions</h5>
                    </Col>
                    <Col lg={3}>
                        <>
                            <div className="datepicker_right">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <GiCreditsCurrency size={20} />
                                    </div>
                                    Filter By
                                </label>

                                <DropDown
                                    placeholder="Select Currency"
                                    options={filterByList}
                                    dataKey=""
                                    isSelected={filterBy}
                                    activeOption={filterBy}
                                    activeOptionId={filterBy}
                                    setOption={(data, index) => {
                                        setFilterBy(data);
                                    }}
                                />

                                {/* <div
                                    className={`dropdown_wp currency_dropdown ${filterDropDown ? "active_dropdown" : ""
                                        }`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setFilterDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setFilterDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setFilterDropdown(false)}>
                                        {filterBy}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {filterByList?.map((filter) => (
                                            <li key={filter} onClick={() => setFilterBy(filter)}>
                                                {filter}
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </>
                    </Col>
                    <Col lg={3}>
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                    <GiCreditsCurrency size={20} />
                                </div>
                                Select Currency
                            </label>

                            <DropDown
                                placeholder="Select Currency"
                                options={currencies}
                                dataKey="currency_abrv"
                                isSelected={currency}
                                activeOption={currency}
                                activeOptionId={currency}
                                setOption={(data, index) => {
                                    setCurrency(data?.currency_abrv);
                                }}
                            />
                        </div>
                        {/* <CurrenciesDropdown
                            currencies={currencies}
                            currency={currency}
                            setCurrency={setCurrency}
                            otherData={numberOfBets}
                            width={width}
                        /> */}
                    </Col>
                    <Col lg={3}>
                        <>
                            <div className="datepicker_right">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <GiCreditsCurrency size={20} />
                                    </div>
                                    Select Game
                                </label>

                                <DropDown
                                    placeholder="Select Currency"
                                    options={allGames}
                                    dataKey="game"
                                    isSelected={selectedGame?.game}
                                    activeOption={selectedGame?.game}
                                    activeOptionId={selectedGame?.game}
                                    setOption={(data, index) => {
                                        setSelectedGame(data);
                                    }}
                                />
                                {/* <div
                                    className={`dropdown_wp currency_dropdown ${gameDropDown ? "active_dropdown" : ""
                                        }`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setGameDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setGameDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setGameDropdown(false)}>
                                        {selectedGame?.game}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {allGames?.map((currency) => (
                                            <li
                                                key={currency.id}
                                                onClick={() => {
                                                    setSelectedGame(currency);
                                                }}>
                                                {currency?.game}
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </>
                    </Col>
                </Row>
            </div>

            {errorMessage ? (
                <p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
            ) : (
                <Chart {...playerChartoption} type="bar" width="100%" height="500px" />
            )}
            {loading ? <Loader /> : ""}
        </>
    );
};

export default GamesBetsTransactionsChart;
