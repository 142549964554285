import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function MyVerticallyCenteredModal(props) {
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="theme_modal"
		>
			<Modal.Header
				closeButton
				className="modal_header"
			>
				<Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal_body">{props.children}</Modal.Body>
			{/* <Modal.Footer className="modal_footer">
				<div className="d-flex gap-4 justify-content-sm-end justify-content-center w-100">
					<button
						onClick={props.onHide}
						className="sec_btn"
					>
						Close
					</button>
					<button
						type="submit"
						onClick={props.onHide}
						className="sec_btn"
					>
						Save
					</button>
				</div>
			</Modal.Footer> */}
		</Modal>
	);
}
