import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../../../components/Loader";
import axios from "axios";
import { GiCreditsCurrency } from "react-icons/gi";
import DropDown from "../../Common/DropDown";
import InputField from "../../Common/InputField";

const GGRByGameAllCasinos = ({ startDate, endDate, currencies, refreshToggle, width }) => {
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");

	const [errorMessage, setErrorMessage] = useState("");
	const [gameTypeData, setGameTypeData] = useState([]);
	const [gameTypes, setGameTypes] = useState([]);
	const [slotTypes, setSlotTypes] = useState([]);
	const [selectedGameType, setSelectedGameType] = useState("All");
	const [selectedSlotType, setSelectedSlotType] = useState("All");
	const [currency, setCurrency] = useState("All");
	const [responseCurrency, setResponseCurrency] = useState("");

	// advance revenue filter data
	const getData = async () => {
		const casino = JSON.parse(localStorage.getItem("currentCasino"));
		setLoading(true);

		try {
			const response = await axios.get(
				`https://api.playerreporting.com/reports/transactions/dashboard/ggr-game-revenue-all-casinos?token=${casino.casino_token}&casino=${casino.casino_key}&currency=${
					currency.toLowerCase() === "all" ? "" : currency
				}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z`
			);

			if (response.data.status === 200) {
				setResponseCurrency(response.data.response?.currency);
				// const sortedGames = response.data.response?.gamesGGR.sort((a, b) => a.ggr - b.ggr);
				// setGameTypeData(sortedGames);
				const responseGameData = response.data.response?.gamesGGR;

				const newGameTypes = [{ gameType: "All" }];
				responseGameData.forEach((data) => {
					if (newGameTypes.findIndex((gt) => gt?.gameType === data?.gameType) === -1) {
						newGameTypes.push(data);
					}
				});
				setGameTypes(newGameTypes);
				const newSlotTypes = [{ slotSize: "All" }];
				responseGameData.forEach((data) => {
					if (data?.slotSize) {
						if (newSlotTypes.findIndex((gt) => gt?.slotSize?.toString() === data?.slotSize?.toString()) === -1) {
							newSlotTypes.push(data);
						}
					}
				});
				setSlotTypes(newSlotTypes);
				setGameTypeData(responseGameData);
			} else {
				setErrorMessage(response?.data?.message);
			}
		} catch (error) {
			if (error.response) {
				setErrorMessage(error.response?.data?.message);
			} else {
				setErrorMessage(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle, currency]);

	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			}
		},
		"dark"
	);
	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			}
		},
		"dark"
	);

	const columns = [
		{
			name: "Game",
			selector: (row) => row.name,
			sortable: true
		},
		{
			name: "GGR",
			selector: (row) => `${parseFloat(row?.ggr) ? (row?.ggr).toFixed(2) : row?.ggr} ${currency.toLowerCase() === "all" ? "Converted to USD" : responseCurrency ? responseCurrency : ""}`,
			sortable: true,
			sortFunction: (a, b) => a.ggr - b.ggr
		},
		{
			name: "Game Type",
			selector: (row) => row.gameType,
			sortable: true
		},
		{
			name: "Slot Type",
			selector: (row) => row.slotSize,
			sortable: true
		},
		{
			name: "RTP",
			selector: (row) => row.rtp,
			sortable: true,
			sortFunction: (a, b) => {
				const rtpA = parseFloat(a.rtp.split("%")[0]);
				const rtpB = parseFloat(b.rtp.split("%")[0]);
				return rtpA - rtpB;
			}
		},
		{
			name: "Number Of Transactions",
			selector: (row) => row.numberOfTxns,
			sortable: true,
			sortFunction: (a, b) => a.numberOfTxns - b.numberOfTxns
		}
	];

	const filteredData = gameTypeData.filter((data) => {
		if (selectedGameType.toLowerCase() === "all" && selectedSlotType.toLowerCase() === "all") {
			return true;
		}
		if (selectedGameType.toLowerCase() === "all" && data?.slotSize === selectedSlotType) {
			return true;
		} else if (selectedSlotType.toLowerCase() === "all" && data.gameType === selectedGameType) {
			return true;
		}
		return data.gameType === selectedGameType && data?.slotSize === selectedSlotType;
	});

	const customFilter = (data) => {
		return data.filter((item) => {
			if (search === "") {
				return item;
			} else {
				return item.name.toLowerCase().includes(search.toLowerCase());
			}
		});
	};

	return (
		<>
			<div className="finance_heapchart mb_30">
				<Row className="align-items-center">
					<Col lg={3}>
						<h5 className="h5_title">
							GGR By Game <small>(All Casinos)</small>
						</h5>
					</Col>
					<Col lg={3}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Slot Type
							</label>

							<DropDown
								placeholder="Select Game Type"
								options={slotTypes}
								dataKey="slotSize"
								isSelected={selectedSlotType}
								activeOption={selectedSlotType}
								activeOptionId={selectedSlotType}
								setOption={(data, index) => {
									setSelectedSlotType(data?.slotSize);
								}}
							/>
						</div>
					</Col>
					<Col lg={3}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Game Type
							</label>

							<DropDown
								placeholder="Select Game Type"
								options={gameTypes}
								dataKey="gameType"
								isSelected={selectedGameType}
								activeOption={selectedGameType}
								activeOptionId={selectedGameType}
								setOption={(data, index) => {
									setSelectedGameType(data?.gameType);
								}}
							/>
						</div>
					</Col>
					<Col lg={3}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
					</Col>
				</Row>
			</div>

			<div className="player_reports_table mb_30">
				<div
					className="table_loader_wp"
					style={{ minHeight: "70px" }}
				>
					{errorMessage ? (
						<p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
					) : filteredData.length < 0 || loading ? (
						<Loader />
					) : (
						<>
							<Col lg={4}>
								<InputField
									type="search"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									placeholder="Search by game"
								/>
							</Col>
							<DataTable
								theme="solarized"
								columns={columns}
								data={customFilter(filteredData)}
								progressPending={loading}
								pagination
								paginationRowsPerPageOptions={[10, 20, 50, 100]}
							/>
						</>
					)}
				</div>
			</div>

			{/* <section className="reports_filter">
            {/* <section className="reports_filter">
				<Row>
					<Col lg={12}>
						<div className="sec_wp mb_30 mt_30">
							<h2 className="h2_title">GGR By Game <small>(All Casinos)</small></h2>
							<div className="divider"></div>
						</div>
					</Col>
				</Row>
				<div className="player_reports_table">
					<div className="table_loader_wp" style={{ minHeight: "70px" }}>
						{errorMessage ? (<p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>) : (
							gameTypeData.length < 0 || loading ? (<Loader />) : (
								<DataTable
									theme="solarized"
									columns={columns}
									data={gameTypeData}
									progressPending={loading}
									pagination
									paginationRowsPerPageOptions={[10, 20, 50, 100]}
								/>
							)
						)}
					</div>
				</div>
			</section> */}
		</>
	);
};

export default GGRByGameAllCasinos;
