import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Loader from "../Loader";
import moment from "moment";
import axios from "axios";

const CasinoChart = ({ startDate, endDate }) => {
    const [chartDate, setChartDate] = useState();
    const [casinoChartData, setCasinoChartData] = useState();
    const [recordType, setRecordType] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // render chart transaction
    const getChartData = async () => {
        setErrorMessage("");
        setLoading(true);
        const casino = JSON.parse(localStorage.getItem("currentCasino"));
        const monthArray = [];
        const chartMonthArray = [];

        await axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/reports/transactions/dashboard/chart?token=${casino?.casino_token}&casino=${casino?.casino_key}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    const data = response.data.response.chartData;
                    if (data?.length === 0) {
                        setErrorMessage("There are no records to display");
                    }

                    data.forEach((item) => {
                        monthArray.push(
                            moment(new Date(item.day)).format(
                                response.data.response.recordType === "months" ? "MMM-YYYY" : "DD-MMM-YYYY"
                            )
                        );
                        chartMonthArray.push(item.ggr);
                    });

                    setRecordType(response.data.response.recordType);
                    setChartDate(response.data.response.daysOrMonths);
                    setCasinoChartData(response.data.response.currencyWiseData);
                } else {
                    setErrorMessage(response?.data?.message);
                }
            })
            .catch((error) => {
                if (error.response) {
                    setErrorMessage(error.response?.data?.message);
                } else {
                    setErrorMessage(error.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    const dashboardChartOptions = {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 3,
            curve: "smooth",
        },
        title: {
            align: "center",
            style: {
                color: "#FEB019",
            },
        },
        xaxis: {
            categories: chartDate ? chartDate : [],
            labels: {
                style: {
                    colors: "#008FFB",
                },
            },
        },
        yaxis: [
            {
                labels: {
                    formatter: (value) => {
                        return value?.toFixed(2);
                    },
                    style: {
                        colors: "#fff",
                    },
                },
            },
        ],
        tooltip: {
            theme: "dark",
            followCursor: true,
        },
        legend: {
            position: "top",
            labels: {
                colors: "#fff",
            },
            markers: {
                offsetX: -4,
                offsetY: -1,
            },
            itemMargin: {
                horizontal: 10,
            },
        },
    };

    const series = casinoChartData ? casinoChartData : [];

    return (
        <div className="casino_chart mt_30 ">
            <div className="table_loader_wp" style={{ minHeight: "70px" }}>
                {errorMessage ? (
                    <p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
                ) : (
                    <>
                        <h5 className="h5_title text-center">{`Report by ${
                            recordType ? recordType.charAt(0).toUpperCase() + recordType.slice(1) : "Month"
                        } - All Currencies`}</h5>
                        <Chart
                            options={dashboardChartOptions}
                            series={series}
                            type="line"
                            width="100%"
                            height="450px"
                        />
                    </>
                )}
                {loading && <Loader />}
            </div>
        </div>
    );
};

export default CasinoChart;
