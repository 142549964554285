import React, { useEffect, useState } from "react";
import moment from "moment";
import { GiAbstract021 } from "react-icons/gi";
import { FcMoneyTransfer, FcCurrencyExchange, FcBullish, FcNews } from "react-icons/fc";
import Loader from "../Loader";
import { Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { formatNum } from "../../utils";
moment.locale("utc");

const ReportsFilter = ({ revenueFilterData, startDate, endDate, responseCurrency, loading, errorMessage }) => {
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const revenueArray = [
            {
                id: 1,
                type: "Gross Gaming Total",
                earning: `${isNaN(Number(revenueFilterData?.totalGrossGaming)?.toFixed(2)) ? "00.00" : formatNum.format(Number(revenueFilterData?.totalGrossGaming))} ${responseCurrency}`,
            },
            {
                id: 2,
                type: "Payout",
                earning: `${isNaN(Number(revenueFilterData?.totalPayout)?.toFixed(2)) ? "00.00" : formatNum.format(Number(revenueFilterData?.totalPayout))} ${responseCurrency}`,
            },
            {
                id: 3,
                type: "Gross Gaming Revenue (GGR)",
                earning: `${isNaN(Number(revenueFilterData?.GGR)?.toFixed(2)) ? "0.00" : formatNum.format(Number(revenueFilterData?.GGR))} ${responseCurrency} `,
            },
            {
                id: 4,
                type: "Taxes",
                earning: `${isNaN(Number(revenueFilterData?.taxes)?.toFixed(2)) ? "00.00" : formatNum.format(Number(revenueFilterData?.taxes))} ${responseCurrency}`,
            },
            {
                id: 5,
                type: "Net Gaming Revenue",
                earning: `${isNaN(Number(revenueFilterData?.netGGR)?.toFixed(2)) ? "00.00" : formatNum.format(Number(revenueFilterData?.netGGR))} ${responseCurrency}`,
            },
        ];
        setFilteredData(revenueArray);
    }, [revenueFilterData]);

    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            grow: "1px",
            width: "50px",
        },
        {
            name: "Type",
            sortable: true,
            selector: (row) => {
                let IconComponent;
                switch (row.type) {
                    case "Gross Gaming Total":
                        IconComponent = FcMoneyTransfer;
                        break;
                    case "Payout":
                        IconComponent = FcCurrencyExchange;
                        break;
                    case "Gross Gaming Revenue (GGR)":
                        IconComponent = FcBullish;
                        break;
                    case "Taxes":
                        IconComponent = FcNews;
                        break;
                    case "Net Gaming Revenue":
                        IconComponent = GiAbstract021;
                        break;
                    default:
                        IconComponent = null;
                        break;
                }
                return (
                    <div>
                        {IconComponent && (
                            <div>
                                <IconComponent className="mr_10" size={26} /> {row.type}
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            name: `${startDate} TO ${endDate}  Earning`,
            selector: (row) => row.earning,
        },
    ];

    return (
        <>
            <section className="reports_filter py_60">
                <Row>
                    <Col lg={12}>
                        <div className="sec_wp mb_30">
                            <h2 className="h2_title">advanced revenue reports</h2>
                            <div className="divider"></div>
                        </div>
                    </Col>
                </Row>

                <div className="player_reports_table ">
                    <div className="table_loader_wp" style={{ minHeight: "70px" }}>
                        {errorMessage ? (
                            <p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
                        ) : revenueFilterData.length < 0 || loading ? (
                            <Loader />
                        ) : (
                            <DataTable theme="solarized" columns={columns} data={filteredData} progressPending={loading} />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ReportsFilter;
