import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../../Loader";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const GameTypeBetsChart = ({ startDate, endDate, currency, width }) => {
	const [chartdata, setChartdata] = useState();
	const [monthlist, setMonthlist] = useState([]);
	const [loading, setLoading] = useState(false);
	const [gameTypeBets, setGameTypeBets] = useState([]);
	const [gameTypes, setGameTypes] = useState([]);
	const [pieChartData, setPieChartData] = useState({ series: [], labels: [], original: { series: [], labels: [] }, disabledIndexes: [] });
	const [selectedGameType, setSelectedGameType] = useState("All");
	const [errorMessage, setErrorMessage] = useState("");
	const [gameDropDown, setGameDropdown] = useState(false);

	const getData = () => {
		const gameType = selectedGameType === "All" ? "" : selectedGameType;
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));

		setLoading(true);

		axios
			.get(
				`https://api.playerreporting.com/games/reports/bets-game-type?token=${casino?.casino_token}&casino=${casino?.casino_key}&currency=${
					currency === "All" ? "" : currency
				}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&gameType=${gameType}`
			)
			.then((response) => {
				if (response.data.status === 200) {
					setChartdata(response.data?.data?.byGameTypes);
				} else {
					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setErrorMessage("");
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, currency, selectedGameType]);

	useEffect(() => {
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));

		axios.get(`https://api.playerreporting.com/games/gametypes/list?token=${casino?.casino_token}&casino=${casino?.casino_key}`).then((response) => {
			if (response.data?.status === 200) {
				if (Array.isArray(response.data?.data) && response.data?.data.length !== 0) {
					setGameTypes(["All", ...response.data?.data]);
				}
			}
		});
	}, []);

	useEffect(() => {
		const gameTypeList = [];
		const betsList = [];

		if (chartdata) {
			// eslint-disable-next-line array-callback-return
			chartdata.map((val, i) => {
				const currGameType = val.gameType;
				gameTypeList.push(currGameType);
				betsList.push(parseFloat(val.bets.toFixed(2)));
			});

			if (!gameTypeList.length && !betsList.length) {
				setErrorMessage("There are no records to display");
			} else {
				setErrorMessage("");
			}
			const modifiedData = { series: betsList, labels: gameTypeList };

			setPieChartData((p) => ({ ...p, ...modifiedData, original: modifiedData }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartdata]);

	const playerChartoption = {
		series: pieChartData.series,
		options: {
			chart: {
				width: "100%",
				type: "pie"
			},
			labels: pieChartData.labels,
			theme: {
				monochrome: {
					enabled: true
				}
			},
			plotOptions: {
				pie: {
					dataLabels: {
						offset: -5
					}
				}
			},
			title: {
				text: ""
			},
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex];
					return [name, parseFloat(val).toFixed(1) + "%"];
				}
			},
			// legend: {
			//     show: false,
			// },
			tooltip: {
				fixed: {
					enabled: true,
					position: "bottom", // topRight, topLeft, bottomRight, bottomLeft
					offsetY: 30,
					offsetX: 60
				},
				theme: "dark"
			},
			legend: {
				horizontalAlign: "left",
				offsetX: 20,
				labels: {
					colors: "#fff"
				},
				markers: {
					offsetX: -3,
					offsetY: 2
				},
				itemMargin: {
					horizontal: 10
				}
			},
			responsive: [
				{
					breakpoint: 575,
					options: {
						legend: {
							position: "bottom"
						}
					}
				}
			]
		}
	};

	const toggleData = (index) => {
		setPieChartData((prev) => {
			if (!prev.disabledIndexes.includes(index)) {
				const disabledIndexes = [...prev.disabledIndexes, index];
				const newSeries = prev.original.series.filter((series, sIndex) => !disabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => !disabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: disabledIndexes };
			} else {
				let updatedDisabledIndexes = [...prev.disabledIndexes];

				updatedDisabledIndexes = updatedDisabledIndexes.filter((el) => el !== index);

				const newSeries = prev.original.series.filter((series, sIndex) => !updatedDisabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => !updatedDisabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: updatedDisabledIndexes };
			}

			return prev;
		});
	};

	return (
		<>
			{/* <div className="datepicker_right bets_game_type_chart">
                <div className={`dropdown_wp currency_dropdown ${gameDropDown ? "active_dropdown" : ""}`}>
                    <button
                        className="sec_btn dropdown_btn transparent_btn"
                        onClick={() => width <= 991 && setGameDropdown((prev) => !prev)}
                        onMouseEnter={() => width >= 991 && setGameDropdown(true)}
                        onMouseLeave={() => width >= 991 && setGameDropdown(false)}
                    >
                        {gameTypes?.active === -1 ? "Select Game" : gameTypes?.game}
                        <span className="dropdown_icon">
                            <MdOutlineKeyboardArrowDown size={22} />
                        </span>
                    </button>
                    <ul className="dropdown_list">
                        {gameTypes?.map((gameType) => (
                            <li key={gameType} onClick={() => setSelectedGameType(gameType)}>
                                {gameType}
                            </li>
                        ))}
                    </ul>
                </div>
            </div> */}

			{errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<>
					<Chart
						{...playerChartoption}
						type="pie"
						width="100%"
						height="500px"
					/>
					<div className="chart_options">
						{pieChartData.original.labels?.map((label, index) => {
							const disabledLabel = pieChartData.disabledIndexes.includes(index);
							return (
								<p
									onClick={() => toggleData(index)}
									className={disabledLabel ? "active_chart_option" : ""}
								>
									{label}
								</p>
							);
						})}
					</div>
				</>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default GameTypeBetsChart;
