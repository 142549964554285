import { useEffect, useState } from "react";
import InputField from "../../components/Common/InputField";
import axios from "axios";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { UserInfoState } from "../../context/UserInformationContext";

const TwoFaSettings = () => {
	const { userInfo, getUserInfo } = UserInfoState();
	const [loading, setLoading] = useState({ generateQrLoading: true, activate2faLoading: false, deactivate2faLoading: false });
	const [qrCodeInfo, setQrCodeInfo] = useState({});
	const [code, setCode] = useState("");
	const [codeError, setCodeError] = useState("");

	useEffect(() => {
		const userInfo = JSON.parse(localStorage.getItem("user-authorization-token"));
		setLoading((prev) => ({ ...prev, generateQrLoading: true }));
		axios
			.post(
				`${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/2fa/generate-2fa.php`,
				{ user_id: userInfo?.userid },
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((response) => {
				if (response.data?.status) {
					setQrCodeInfo(response.data);
				} else {
					toast.error(response.data?.message, { theme: "dark", autoClose: 1500 });
				}
			})
			.catch((error) => {
				toast.error(error?.message, { theme: "dark", autoClose: 1500 });
			})
			.finally(() => {
				setLoading((prev) => ({ ...prev, generateQrLoading: false }));
			});
	}, []);

	const activate2Fa = () => {
		setCodeError("");
		if (!code) {
			setCodeError("Please enter code");
			return;
		} else if (code.length !== 6) {
			setCodeError("Please enter six digit valid code");
			return;
		}
		const userInfo = JSON.parse(localStorage.getItem("user-authorization-token"));
		setLoading((prev) => ({ ...prev, activate2faLoading: true }));
		axios
			.post(
				`${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/2fa/activate-2fa.php`,
				{ user_id: userInfo?.userid, code, secret: qrCodeInfo?.secret },
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((response) => {
				if (response.data?.status) {
					toast.success(response.data?.message, { theme: "dark", autoClose: 1500 });
					getUserInfo(userInfo?.userid, userInfo?.token);
				} else {
					toast.error(response.data?.message, { theme: "dark", autoClose: 1500 });
				}
			})
			.catch((error) => {
				toast.error(error?.message, { theme: "dark", autoClose: 1500 });
			})
			.finally(() => {
				setLoading((prev) => ({ ...prev, activate2faLoading: false }));
			});
	};
	const deactivate2fa = () => {
		const userInfo = JSON.parse(localStorage.getItem("user-authorization-token"));
		setLoading((prev) => ({ ...prev, deactivate2faLoading: true }));
		axios
			.post(
				`${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/2fa/deactivate-2fa.php`,
				{ user_id: userInfo?.userid },
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((response) => {
				if (response.data?.status) {
					toast.success(response.data?.message, { theme: "dark", autoClose: 1500 });
					getUserInfo(userInfo?.userid, userInfo?.token);
				} else {
					toast.error(response.data?.message, { theme: "dark", autoClose: 1500 });
				}
			})
			.catch((error) => {
				toast.error(error?.message, { theme: "dark", autoClose: 1500 });
			})
			.finally(() => {
				setLoading((prev) => ({ ...prev, deactivate2faLoading: false }));
			});
	};

	return (
		<>
			{loading.generateQrLoading ? (
				<Loader />
			) : (
				<>
					{!userInfo?.is2fa_enabled && (
						<div className="security_content_wrapper">
							<p className="security_authenticator_title">Scan this QR code in the Authenticator app to add this account. some authenticator apps also allow you to type in the text version instead.</p>
							<div className="security_content">
								<div className="security_qr_img">
									<img
										src={qrCodeInfo.qr_code}
										alt="QR code"
									/>
								</div>

								<div className="secret_key_input">
									<div className="ca_details_input">
										<span>{qrCodeInfo?.secret}</span>
									</div>
								</div>
							</div>

							<div className="security_code_input">
								<p>Enter the code from your authenticator app below to verify and activate two-factor authenticator for this account.</p>
								<InputField
									onInput={(e) => {
										if (e.target.value) {
											const decimals = e.target.value.length;
											if (decimals > 6) e.target.value = e.target.value.slice(0, 6);
										}
									}}
									onChange={(e) => setCode(e.target.value)}
									value={code}
									type="number"
									name="security_code"
									placeholder="123456"
									errorMessage={codeError}
								/>
								<button
									className="sec_btn"
									onClick={activate2Fa}
								>
									Activate {loading.activate2faLoading && <Loader size={22} />}
								</button>
							</div>
						</div>
					)}

					{userInfo?.is2fa_enabled && (
						<div className="security_code_disabled">
							<p>Two-factor authentication is currently active on your account. You may deactivate it by clicking the button below.</p>

							<button
								className="sec_btn"
								onClick={deactivate2fa}
							>
								deactivate {loading.deactivate2faLoading && <Loader size={22} />}
							</button>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default TwoFaSettings;
