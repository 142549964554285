import moment from "moment";
const startDate = moment("2023-01-01");
const endDate = moment();
const betweenMonths = [];

if (startDate < endDate) {
	const date = startDate.startOf("month");
	for (date; date < endDate.endOf("year"); date.add(1, "month")) {
		betweenMonths.push({ label: date.format("MMMM YYYY"), value: date.format("MMMM YYYY") });
	}
}

export const monthList = betweenMonths.sort((a, b) => new Date(b.value) - new Date(a.value));
