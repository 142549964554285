import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../Loader";
import { formatNum } from "../../utils";
import InputField from "../Common/InputField";

const GameTypeFilter = ({ gameTypeGGRdata, responseCurrency, loading, errorMessage }) => {
	const [search, setSearch] = useState("");
	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			}
		},
		"dark"
	);

	const columns = [
		{
			name: "Type",
			selector: (row) => row.name,
			sortable: true
		},
		{
			name: "GGR",
			selector: (row) => `${formatNum.format(row?.ggr)} ${responseCurrency}`,
			sortable: true,
			sortFunction: (a, b) => a.ggr - b.ggr
		}
	];

	const customFilter = (data) => {
		return data.filter((item) => {
			if (search === "") {
				return item;
			} else {
				return item.name.toLowerCase().includes(search.toLowerCase());
			}
		});
	};

	return (
		<>
			<section className="reports_filter">
				<Row>
					<Col lg={12}>
						<div className="sec_wp mb_30">
							<h2 className="h2_title">GGR By Game</h2>
							<div className="divider"></div>
						</div>
					</Col>
				</Row>
				<div className="player_reports_table">
					<div
						className="table_loader_wp"
						style={{ minHeight: "70px" }}
					>
						{errorMessage ? (
							<p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
						) : gameTypeGGRdata?.length < 0 || loading ? (
							<Loader />
						) : (
							<>
								<Col lg={4}>
									<InputField
										type="search"
										value={search}
										onChange={(e) => setSearch(e.target.value)}
										placeholder="Search by type"
									/>
								</Col>
								<DataTable
									theme="solarized"
									columns={columns}
									data={customFilter(gameTypeGGRdata)}
									progressPending={loading}
									pagination
									paginationRowsPerPageOptions={[10, 20, 50, 100]}
								/>
							</>
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export default GameTypeFilter;
