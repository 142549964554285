import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { BsCalendar2Date } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GiCreditsCurrency } from "react-icons/gi";
import CasinoChart from "../../components/MainDashboard/CasinoChart";
import Revenue from "../../components/MainDashboard/Revenue";
import ReportsFilter from "../../components/MainDashboard/ReportsFilter";
import GGRbyGame from "../../components/MainDashboard/GgrByGame";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DropDown from "../../components/Common/DropDown";
moment.locale("utc");

const Casino = () => {
    const [revenueFilterData, setRevenueFilterData] = useState([]);
    const [gameTypeData, setGameTypeData] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState("All");
    const [curData, setCurrencyData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [advanceRevenueError, setAdvanceRevenueError] = useState("");
    const [advanceRevenueFilterLoading, setAdvanceRevenueFilterLoading] = useState(false);
    const [dropDown, setDropdown] = useState(false);
    const [responseCurrency, setResponseCurrency] = useState(false);
    const [width, setWidth] = useState();
    const [state, setState] = useState({
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
    });
    // const [date, setDate] = useState({
    //     start: moment().startOf("month").format("YYYY-MM-DD"),
    //     end: moment().endOf("month").format("YYYY-MM-DD"),
    // });
    const { start, end } = state;

    // render currency list
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/currencies/list?token=${
                    JSON.parse(localStorage.getItem("currentCasino"))?.casino_token
                }`
            )
            .then((response) => {
                if (response?.data?.status === 200) {
                    setCurrencyData([{ currency_abrv: "All" }, ...response?.data?.data]);
                } else {
                    setErrorMessage(response?.data?.message);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(error.response?.data?.message, {
                        theme: "dark",
                        autoClose: 1500,
                    });
                } else {
                    toast.error(error.message, { theme: "dark", autoClose: 1500 });
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCallback = (start, end) => {
        setState({ start, end });
    };

    // const startAndEndDate = (start, end) => {
    //     setDate({ start, end });
    // };

    // advance revenue filter data
    const getAdvanceRevenueData = async () => {
        setAdvanceRevenueFilterLoading(true);
        const casino = JSON.parse(localStorage.getItem("currentCasino"));
        const taxRate = casino?.tax_rate.replace(/%/g, "");

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/reports/transactions/dashboard/ggr-game-revenue?token=${
                    casino.casino_token
                }&casino=${casino.casino_key}&currency=${
                    selectedCurrency.toLowerCase() === "all" ? "" : selectedCurrency
                }&start=${moment(start).format("YYYY-MM-DD")}T00:00:00Z&end=${moment(end).format(
                    "YYYY-MM-DD"
                )}T23:59:59Z&tax_rate=${taxRate}`
            );

            if (response.data.status === 200) {
                setRevenueFilterData(response.data.response);
                const sortedGames = response.data.response?.gamesGGR.sort((a, b) => a.ggr - b.ggr);
                setGameTypeData(sortedGames);
                setResponseCurrency(response.data?.currency);
            } else {
                setAdvanceRevenueError(response?.data?.message);
            }
        } catch (error) {
            if (error.response) {
                setAdvanceRevenueError(error.response?.data?.message);
            } else {
                setErrorMessage(error.message);
            }
        } finally {
            setAdvanceRevenueFilterLoading(false);
        }
    };

    const selectCurrency = (currency) => {
        setSelectedCurrency(currency);
    };

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        getAdvanceRevenueData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, end, selectedCurrency]);

    return (
        <>
            <div className="form_box_wp casino_chart mb_30">
                <div className="datepicker_wp mb_30">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="date_picker_wrapper">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <BsCalendar2Date size={20} />
                                    </div>
                                    Select Date
                                </label>
                                <DateRangePicker
                                    initialSettings={{
                                        startDate: moment().startOf("month").toDate(),
                                        endDate: moment().endOf("month").toDate(),
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, "days").toDate(),
                                                moment().subtract(1, "days").toDate(),
                                            ],
                                            "Last 7 Days": [
                                                moment().subtract(6, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "Last 30 Days": [
                                                moment().subtract(29, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "This Month": [
                                                moment().startOf("month").toDate(),
                                                moment().endOf("month").toDate(),
                                            ],
                                            "Last Month": [
                                                moment().subtract(1, "month").startOf("month").toDate(),
                                                moment().subtract(1, "month").endOf("month").toDate(),
                                            ],
                                        },
                                    }}
                                    onCallback={handleCallback}>
                                    <input type="text" className="form_input" />
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="col-lg-5"></div>
                        {/* <div className="col-lg-3">
                            <div className="datepicker_right">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <GiCreditsCurrency size={20} />
                                    </div>
                                    Select Currency
                                </label>
                                <DropDown
                                    placeholder="Select Month"
                                    options={curData}
                                    dataKey="currency_abrv"
                                    isSelected={selectedCurrency}
                                    activeOption={selectedCurrency}
                                    activeOptionId={selectedCurrency}
                                    setOption={(data, index) => {
                                        setSelectedCurrency(data?.currency_abrv);
                                    }}
                                />
                                <div className={`dropdown_wp currency_dropdown ${dropDown ? "active_dropdown" : ""}`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setDropdown(false)}
                                    >
                                        {selectedCurrency ? selectedCurrency : ""}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {curData?.map((currency) => (
                                            <li
                                                key={currency.currency_abrv}
                                                onClick={() => {
                                                    selectCurrency(currency.currency_abrv);
                                                }}
                                            >
                                                {currency?.currency_abrv}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="table_loader_wp">
                    <CasinoChart
                        startDate={moment(start).format("YYYY-MM-DD")}
                        endDate={moment(end).format("YYYY-MM-DD")}
                    />
                </div>
            </div>

            
                <div className="datepicker_wp mb_30">
                    <div className="row">
                        <div className="col-lg-4">
                            {/* <div className="date_picker_wrapper">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <BsCalendar2Date size={20} />
                                    </div>
                                    Select Date
                                </label>
                                <DateRangePicker
                                    initialSettings={{
                                        startDate: moment().startOf("month").toDate(),
                                        endDate: moment().endOf("month").toDate(),
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, "days").toDate(),
                                                moment().subtract(1, "days").toDate(),
                                            ],
                                            "Last 7 Days": [
                                                moment().subtract(6, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "Last 30 Days": [
                                                moment().subtract(29, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "This Month": [
                                                moment().startOf("month").toDate(),
                                                moment().endOf("month").toDate(),
                                            ],
                                            "Last Month": [
                                                moment().subtract(1, "month").startOf("month").toDate(),
                                                moment().subtract(1, "month").endOf("month").toDate(),
                                            ],
                                        },
                                    }}
                                    onCallback={startAndEndDate}>
                                    <input type="text" className="form_input" />
                                </DateRangePicker>
                            </div> */}
                            <div className="sec_wp mb_30">
                            <h2 className="h2_title">revenue by period</h2>
                            {/* <div className="divider"></div> */}
                        </div>
                        </div>
                        <div className="col-lg-5"></div>
                        <div className="col-lg-3">
                            <div className="datepicker_right">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <GiCreditsCurrency size={20} />
                                    </div>
                                    Select Currency
                                </label>
                                <DropDown
                                    placeholder="Select Currency"
                                    options={curData}
                                    dataKey="currency_abrv"
                                    isSelected={selectedCurrency}
                                    activeOption={selectedCurrency}
                                    activeOptionId={selectedCurrency}
                                    setOption={(data, index) => {
                                        setSelectedCurrency(data?.currency_abrv);
                                    }}
                                />
                                {/* <div
                                    className={`dropdown_wp currency_dropdown ${
                                        dropDown ? "active_dropdown" : ""
                                    }`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setDropdown(false)}>
                                        {selectedCurrency ? selectedCurrency : ""}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {curData?.map((currency) => (
                                            <li
                                                key={currency.currency_abrv}
                                                onClick={() => {
                                                    selectCurrency(currency.currency_abrv);
                                                }}>
                                                {currency?.currency_abrv}
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table_loader_wp">
                    <Revenue
                        currency={selectedCurrency}
                        startDate={moment(start).format("YYYY-MM-DD")}
                        endDate={moment(end).format("YYYY-MM-DD")}
                    />

                    <ReportsFilter
                        startDate={moment(start).format("YYYY-MM-DD")}
                        endDate={moment(end).format("YYYY-MM-DD")}
                        responseCurrency={responseCurrency}
                        revenueFilterData={revenueFilterData}
                        loading={advanceRevenueFilterLoading}
                        errorMessage={advanceRevenueError}
                    />

                    <GGRbyGame
                        gameTypeGGRdata={gameTypeData}
                        loading={advanceRevenueFilterLoading}
                        responseCurrency={responseCurrency}
                        errorMessage={errorMessage}
                    />
                </div>
            
        </>
    );
};

export default Casino;
