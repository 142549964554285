import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../Loader";
import { GiCreditsCurrency } from "react-icons/gi";
import DropDown from "../Common/DropDown";
import InputField from "../Common/InputField";

const AllBrandsGGRTable = ({ startDate, endDate, currencies, refreshToggle, width }) => {
	const [currency, setCurrency] = useState("All");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [data, setData] = useState([]);
	const [sort, setSort] = useState({ field: "", direction: "" });
	const [search, setSearch] = useState("");

	const getData = async () => {
		setErrorMessage("");
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		const allCasinos = JSON.parse(localStorage.getItem("userInformation"));
		const brands = Object.groupBy(allCasinos?.casino_information, (casino) => casino.brand);

		setLoading(true);
		await axios
			.post(
				`https://api.playerreporting.com/reports/transactions/dashboard/brands-ggr?currency=${currency === "All" ? "" : currency}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&token=${
					casino?.casino_token
				}&casino=${casino?.casino_key}&sortBy=${sort.field}&orderBy=${sort.direction}`,
				{ brands }
			)
			.then((response) => {
				if (response.data.status === 200) {
					setData(response?.data?.response?.data);
				} else {
				}
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, [refreshToggle, currency, sort]);

	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			},
			pagination: {
				fontSize: "1rem",
				fontFamily: "inherit",
				textAlign: "right"
			}
		},
		"dark"
	);

	const columns = [
		{
			name: "Brand",
			selector: (row) => row?.name,
			sortable: true,
			sortField: "name"
		},
		{
			name: "GGR",
			selector: (row) => `${row?.GGR?.toFixed(2)} ${row?.currency}`,
			sortable: true,
			sortField: "GGR"
		},
		{
			name: "Fee UG (In %)",
			selector: (row) => row?.royaltiesKey,
			sortable: true,
			sortField: "royaltiesKey"
		},
		{
			name: "Fee UG",
			selector: (row) => row?.royalties?.toFixed(2),
			sortable: true,
			sortField: "royalties"
		}
	];

	const handleSort = (column, sortDirection) => {
		setSort({ field: column.sortField, direction: sortDirection });
	};

	const customFilter = (data) => {
		return data.filter((item) => {
			if (search === "") {
				return item;
			} else {
				return item.name.toLowerCase().includes(search.toLowerCase());
			}
		});
	};

	return (
		<>
			<div className="finance_heapchart mb_30">
				<Row className="align-items-center">
					<Col lg={4}>
						<h5 className="h5_title">All Brands GGR</h5>
					</Col>
					<Col lg={5}></Col>
					<Col lg={3}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
					</Col>
				</Row>
			</div>

			{errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<>
					<Col lg={4}>
						<InputField
							type="search"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Search by brand"
						/>
					</Col>
					<DataTable
						data={customFilter(data)}
						columns={columns}
						theme="solarized"
						onSort={handleSort}
						sortServer
					/>
				</>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default AllBrandsGGRTable;
