import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { TbFileInvoice } from "react-icons/tb";
import { AiOutlineLogout } from "react-icons/ai";
import { RiLoader4Line } from "react-icons/ri";
import { MdLocationPin } from "react-icons/md";

const UserProfile = ({ activeDropdown, setActiveDropdown, width }) => {
    const [casinoInfo, setCasinoInfo] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [selectCasino, setSelectcasino] = useState();
    const [dropDown, setDropdown] = useState(false);

    /** On load to get user data Start */
    useEffect(() => {
        setCasinoInfo(JSON.parse(localStorage.getItem("userInformation"))?.casino_information);
        setUserInfo(JSON.parse(localStorage.getItem("userInformation")));
    }, []);

    /** set default game */
    useEffect(() => {
        if (casinoInfo && casinoInfo?.length > 0) {
            var casinoDetails = localStorage?.getItem("currentCasino");
            if (casinoDetails === null) {
                // Also updating on login

                localStorage?.setItem("currentCasino", JSON?.stringify(casinoInfo[0]));
                casinoDetails = JSON?.parse(localStorage?.getItem("currentCasino"));
            }
            setSelectcasino(casinoDetails);
        }
    }, [casinoInfo]);

    /** User logout */
    const userLogout = () => {
        localStorage?.removeItem("user-authorization-token");
        localStorage?.removeItem("currentCasino");
        localStorage?.removeItem("userInformation");
    };

    return (
        <div
            className={`dropdown_wp dropdown_user_profile ${activeDropdown === "profile" ? "active_dropdown" : ""
                }`}>
            {!userInfo ? (
                <RiLoader4Line size={25} />
            ) : (
                <>
                    <button
                        className="sec_btn dropdown_btn secondary_btn"
                        onClick={() =>
                            width <= 991 &&
                            setActiveDropdown((prev) => (activeDropdown === "profile" ? "" : "profile"))
                        }
                        onMouseEnter={() => width >= 991 && setActiveDropdown("profile")}
                        onMouseLeave={() => width >= 991 && setActiveDropdown("")}>
                        <div className="dropdown_user_profile_img">
                            <img src={userInfo?.profile_img} alt="" width={42} height={42} />
                        </div>
                        <div className="dropdown_user_profile_title">
                            <strong>{userInfo?.user_login}</strong>
                            <span>
                                <MdLocationPin />
                                <strong>{selectCasino ? selectCasino?.casino_name : ""}</strong>
                            </span>
                        </div>
                        <span className="dropdown_icon">
                            <MdOutlineKeyboardArrowDown size={22} />
                        </span>
                    </button>

                    <ul className="dropdown_list">
                        <li>
                            <div className="divider"></div>
                            <NavLink to="/login" onClick={userLogout} title="logout">
                                <AiOutlineLogout size={24} /> logout
                            </NavLink>
                        </li>
                    </ul>
                </>
            )}
        </div>
    );
};

export default UserProfile;
