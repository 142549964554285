import { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { UserInfoState } from "../../context/UserInformationContext";

const CasinoName = ({ activeDropdown, setActiveDropdown, width }) => {
    const { userInfo } = UserInfoState();
    const [casinoInfo, setCasinoInfo] = useState([]);
    const [selectCasino, setSelectcasino] = useState();
    const [casinoName, setCasinoName] = useState([]);

    // /** On load to get user data Start */
    useEffect(() => {
        setCasinoInfo(userInfo?.casino_information);
    }, [userInfo]);

    // sorting by casino name
    useEffect(() => {
        let cname = casinoInfo?.sort((a, b) => a?.casino_name?.localeCompare(b?.casino_name));
        setCasinoName(cname);
    }, [casinoInfo, casinoName]);

    /** set default game */
    useEffect(() => {
        if (casinoInfo?.length > 0) {
            var casinoDetails = localStorage?.getItem("currentCasino");
            if (casinoDetails === null) {
                localStorage.setItem("currentCasino", JSON.stringify(casinoInfo[0]));
                casinoDetails = localStorage?.getItem("currentCasino");
            }
            setSelectcasino(JSON.parse(casinoDetails));
        }
    }, [casinoInfo]);

    /** Select Games event */
    const selectGame = (index) => {
        localStorage.setItem("currentCasino", JSON.stringify(casinoInfo[index]));
        const casinoDetails = localStorage?.getItem("currentCasino");
        setSelectcasino(JSON.parse(casinoDetails));
        window.location.reload(false);
    };

    return (
        <div
            className={`dropdown_wp casino_name_dropdown ${
                activeDropdown === "casino" ? "active_dropdown" : ""
            }`}>
            <button
                className="sec_btn dropdown_btn transparent_btn"
                onClick={() =>
                    width <= 991 && setActiveDropdown((prev) => (activeDropdown === "casino" ? "" : "casino"))
                }
                onMouseEnter={() => width >= 991 && setActiveDropdown("casino")}
                onMouseLeave={() => width >= 991 && setActiveDropdown("")}>
                {selectCasino ? selectCasino?.casino_name : ""}
                <span className="dropdown_icon">
                    <MdOutlineKeyboardArrowDown size={22} />
                </span>
            </button>

            <ul className="dropdown_list">
                {casinoName &&
                    casinoName?.map((info, index) => {
                        return (
                            <li
                                key={"token_" + index}
                                onClick={() => {
                                    selectGame(index);
                                }}>
                                {info?.casino_name}
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default CasinoName;
