import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../../Loader";

const NumberOfBetsPerDayChart = ({ startDate, endDate, currency }) => {
    const [chartdata, setChartdata] = useState();
    const [monthlist, setMonthlist] = useState([]);
    const [numberOfBets, setNumberOfBets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const getData = () => {
        const casino = JSON.parse(localStorage?.getItem("currentCasino"));

        setLoading(true);

        axios
            .get(
                `https://api.playerreporting.com/games/reports/transactions-bets-per-day?token=${
                    casino?.casino_token
                }&casino=${casino?.casino_key}&currency=${
                    currency === "All" ? "" : currency
                }&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    setChartdata(response.data?.response);
                } else {
                    setErrorMessage(response?.data?.message);
                }
            })
            .catch((error) => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setErrorMessage("");
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, currency]);

    useEffect(() => {
        var monthllistarr = [];
        var numberOfBetsList = [];

        if (chartdata) {
            // eslint-disable-next-line array-callback-return
            chartdata.map((val, i) => {
                var cudate = val.date;
                monthllistarr.push(cudate);
                numberOfBetsList.push(parseFloat(val.numberOfBets));
            });

            if (
                !monthllistarr.length &&
                !numberOfBetsList.length /* !betwonarr.length && !betlostarr.length && !netProfitArr.length*/
            ) {
                setMonthlist([]);
                setNumberOfBets([]);
                setErrorMessage("There are no records to display");
            } else {
                setErrorMessage("");
                setMonthlist(monthllistarr);
                setNumberOfBets(numberOfBetsList);
            }
        }
    }, [chartdata]);

    const playerChartoption = {
        options: {
            chart: {
                type: "bar",
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    colors: {
                        ranges: [
                            { from: -100, to: -46, color: "#F15B46" },
                            { from: -45, to: 0, color: "#FEB019" },
                        ],
                    },
                    columnWidth: "80%",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: [1, 1, 4],
            },
            yaxis: {
                padding: {
                    left: 0,
                    right: 0,
                },
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    // offsetX: -12,
                    show: true,
                    color: "#008FFB",
                },
                title: {
                    text: "Number of Bets",
                    style: {
                        color: "#008FFB",
                    },
                },
                labels: {
                    formatter: function (y) {
                        return y;
                    },
                    style: {
                        colors: "#008FFB",
                    },
                },
                tooltip: {
                    enabled: true,
                },
            },
            xaxis: {
                // type: 'datetime',
                categories: monthlist,
                labels: {
                    rotate: -90,
                    style: {
                        colors: "#008FFB",
                    },
                },
            },
            tooltip: {
                fixed: {
                    enabled: true,
                    position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60,
                },
                theme: "dark",
            },
            legend: {
                horizontalAlign: "left",
                offsetX: 20,
                labels: {
                    colors: "#fff",
                },
                markers: {
                    offsetX: -3,
                    offsetY: 2,
                },
                itemMargin: {
                    horizontal: 10,
                },
            },
            grid: {
                padding: {
                    left: 0,
                    right: 0,
                },

                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        },
        series: [
            {
                name: "Number of Bets",
                data: numberOfBets,
            },
        ],
    };

    return (
        <>
            {errorMessage ? (
                <p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
            ) : (
                <Chart {...playerChartoption} type="bar" width="100%" height="500px" />
            )}

            {loading ? <Loader /> : ""}
        </>
    );
};

export default NumberOfBetsPerDayChart;
