import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DropDown from "../Common/DropDown";
import { MdOutlineCasino } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Loader";
import InputField from "../Common/InputField";
import Select from "react-select";
import { monthList } from "../../utils/monthList";
import { UserInfoState } from "../../context/UserInformationContext";
import Verify2fa from "../Common/Verify2fa";
import MyVerticallyCenteredModal from "./BonusDeductionGamesModal";
import MultiSelectDropDown from "../Common/MultiSelectDropDown";
import RoyaltiesGameTypes from "./RoyaltiesGameTypes";
import { IoMdOpen } from "react-icons/io";

const defaultRoyaltiesGameTypes = {
	gameType: "",
	royalties: ""
};

const defaultBrandData = {
	brand: "",
	brandUniqueID: "",
	invoiceCurrency: "USD",
	taxRate: "",
	royalties: "",
	dueDate: "",
	bonusDeduction: "",
	invoiceFrom: "",
	billTo: "",
	bankAccountOrWalletAddress: "",
	bonus_applicable_months: "",
	bonus_applicable_games: "",
	billing_email: "",
	royalties_applicable_game_types: [{ ...defaultRoyaltiesGameTypes }]
};

const actionResponseNames = {
	CREATE: "Added",
	UPDATE: "Updated",
	DELETE: "Deleted"
};

const BrandSettings = ({ brands, currencies, updateBrands, isError }) => {
	const { userInfo } = UserInfoState();
	const [selectedBrand, setSelectedBrand] = useState(brands?.[0]);
	const [isAddBrand, setIsAddBrand] = useState(false);
	const [formData, setFormData] = useState(defaultBrandData);
	const [isLoading, setIsLoading] = useState(false);
	const [allGames, setAllGames] = useState([]);
	const [twoFaSetting, setTwoFaSetting] = useState({
		verifiedOtp: false,
		isDisplayCancelButton: false,
		isClicked: false
	});
	const [bonusDeductionGamesModal, setBonusDeductionGamesModal] = useState(false);
	const [gameTypes, setGameTypes] = useState([]);
	const [inputFields, setInputFields] = useState([]);

	useEffect(() => {
		try {
			const royaltiesTypeArray = JSON.parse(formData.royalties_applicable_game_types).map((item) => ({ ...item, gameType: { label: item.gameType, value: item.gameType } }));
			setInputFields(royaltiesTypeArray);
		} catch (error) {
			setInputFields([{ ...defaultRoyaltiesGameTypes }]);
		}
	}, [formData, selectedBrand]);

	useEffect(() => {
		if (!isError && !brands.length) {
			setIsAddBrand(true);
		}
	}, [isError]);

	useEffect(() => {
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		axios.get(`https://api.playerreporting.com/games/list?token=${casino?.casino_token}`).then((response) => {
			const formattedGamesData = response.data?.data.map((game) => ({ value: game.id, label: game.game }));
			setAllGames(formattedGamesData);
		});
	}, []);

	const handleFormData = (key, data) => {
		setFormData((prev) => {
			if ((key === "brand" && !data.length) || (key !== "brand" && !prev.brand.length)) {
				prev.error = {
					[key]: "Brand name is required",
					[key]: "Brand name is required"
				};
			} else {
				delete prev.error;

				const matchedBrandIndex = key === "brand" ? brands.findIndex((el) => el?.brand?.toLowerCase()?.trim() === data.toLowerCase().trim()) : -1;
				if (matchedBrandIndex !== -1) {
					prev.error = {
						[key]: "Brand name already exists"
					};
				}
			}
			return { ...prev, [key]: data };
		});
	};

	useEffect(() => {
		if (isAddBrand) {
			setFormData(defaultBrandData);
		} else {
			setFormData({
				brand: selectedBrand?.brand,
				brandID: selectedBrand?.brand_id,
				invoiceCurrency: selectedBrand?.invoice_currency,
				taxRate: selectedBrand?.tax_rate,
				royalties: selectedBrand?.royalties,
				dueDate: selectedBrand?.due_date,
				bonusDeduction: selectedBrand?.bonus_deduction,
				invoiceFrom: selectedBrand?.invoice_from,
				billTo: selectedBrand?.bill_to,
				bankAccountOrWalletAddress: selectedBrand?.bank_account_or_wallet_address,
				bonus_applicable_months: selectedBrand?.bonus_applicable_months,
				bonus_applicable_games: selectedBrand?.bonus_applicable_games,
				billing_email: selectedBrand?.billing_email,
				royalties_applicable_game_types: selectedBrand?.royalties_applicable_game_types
			});
		}
	}, [isAddBrand]);

	useEffect(() => {
		setSelectedBrand({ ...brands?.[0] });
	}, [brands]);

	useEffect(() => {
		setFormData({
			brand: selectedBrand?.brand,
			brandID: selectedBrand?.brand_id,
			invoiceCurrency: selectedBrand?.invoice_currency,
			taxRate: selectedBrand?.tax_rate,
			royalties: selectedBrand?.royalties,
			dueDate: selectedBrand?.due_date,
			bonusDeduction: selectedBrand?.bonus_deduction,
			invoiceFrom: selectedBrand?.invoice_from,
			billTo: selectedBrand?.bill_to,
			bankAccountOrWalletAddress: selectedBrand?.bank_account_or_wallet_address,
			bonus_applicable_months: selectedBrand?.bonus_applicable_months,
			bonus_applicable_games: selectedBrand?.bonus_applicable_games,
			billing_email: selectedBrand?.billing_email,
			royalties_applicable_game_types: selectedBrand?.royalties_applicable_game_types
		});
	}, [selectedBrand]);

	const brandSubmitHandler = async (action) => {
		if (userInfo?.is2fa_enabled && !twoFaSetting.isClicked) {
			setTwoFaSetting((prev) => ({ ...prev, isClicked: true }));
			return;
		}

		if (action === "DELETE") {
			if (!window.confirm(`Are you want to permanently delete brand "${selectedBrand?.brand}"?`)) {
				return;
			}
		}

		if (action !== "DELETE" && !formData.brand.length) {
			setFormData((prev) => {
				if (!prev.brand.length) {
					prev.error = {
						brand: "Brand name is required"
					};
				} else {
					delete prev.error;
				}
				return { ...prev };
			});
			return;
		}

		const brandID = selectedBrand?.brand_count;
		const userID = JSON.parse(localStorage.getItem("user-authorization-token"))?.userid;
		const payload = {
			userID,
			action,
			...(action !== "CREATE" && {
				brandID: brandID
			}),
			brandData: {
				brand: formData.brand,
				tax_rate: formData.taxRate,
				royalties: formData.royalties,
				invoice_currency: formData.invoiceCurrency,
				bonus_deduction: formData.bonusDeduction,
				due_date: formData.dueDate,
				invoice_from: formData.invoiceFrom,
				bill_to: formData.billTo,
				bank_account_or_wallet_address: formData.bankAccountOrWalletAddress,
				bonus_applicable_months: formData.bonus_applicable_months,
				bonus_applicable_games: formData.bonus_applicable_games,
				billing_email: formData.billing_email,
				royalties_applicable_game_types: formData.royalties_applicable_game_types
			}
		};

		setIsLoading(true);
		await axios
			.post("https://admin.playerreporting.com/api/casino-brands.php", payload, {
				mode: "no-cors",
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "POST",
					"Content-Type": "multipart/form-data"
				}
			})
			.then((e) => {
				if (e.data?.status) {
					if (action === "CREATE") {
						setIsAddBrand((prev) => !prev);
					}
					toast.success(`Brand ${actionResponseNames[action]?.toLowerCase()} successfully`, {
						theme: "dark",
						autoClose: 3000
					});
					updateBrands(e.data?.data);
					if (action === "DELETE") {
						setSelectedBrand({ ...e.data?.data?.[0] });
					}
				} else {
					toast.error(e.data?.data, { theme: "dark", autoClose: 3000 });
				}
			})
			.catch((error) => {
				toast.error(error.message, { theme: "dark", autoClose: 3000 });
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		axios
			.get(`${process.env.REACT_APP_API_DOMAIN}/games/list?token=${casino?.casino_token}`)
			.then((response) => {
				if (response?.data?.status === 200) {
					const data = response?.data?.data;
					const gameTypes = data
						?.filter((item, index) => item.game_type && item.game_type?.toLowerCase() !== "slot" && data?.findIndex((el) => el.game_type === item.game_type) === index)
						.map((item) => ({ value: item.game_type, label: item.game_type }));
					setGameTypes(gameTypes);
				}
			})
			.catch((error) => {
				toast.error(error.message, { theme: "dark", autoClose: 3000 });
			});
	}, []);

	return (
		<form className="dashboard_form">
			<div className="form_box_wp home_casino_token mt_30">
				<div
					className="table_loader_wp"
					style={{ minHeight: "70px" }}
				>
					{isLoading && <Loader />}
					{!isLoading && (
						<div className="form_box">
							<Row className="mb-md-3 mb-3 justify-content-end">
								{/* mb-3 */}
								<Col lg={12}>
									<Row>
										<Col lg={4}>
											<h4 className="form_title h4_title">Brand Settings</h4>
										</Col>

										<Col
											lg={8}
											className="d-flex align-items-sm-start align-items-center justify-content-end gap-4 mb-4 flex-sm-row flex-column"
										>
											{!isAddBrand && (
												<div className=" dropdown_home_brand">
													<DropDown
														placeholder="Select Brand"
														options={brands}
														dataKey="brand"
														isSelected={selectedBrand?.brand}
														activeOption={selectedBrand?.brand}
														activeOptionId={selectedBrand?.brand}
														setOption={(data, index) => {
															setSelectedBrand({ ...data });
														}}
													/>
												</div>
											)}
											{userInfo?.is2fa_enabled && !twoFaSetting.verifiedOtp && twoFaSetting.isClicked ? (
												<>
													<Verify2fa
														twoFaSetting={twoFaSetting}
														setTwoFaSetting={setTwoFaSetting}
													/>
												</>
											) : (
												<>
													<div className="d-flex align-items-center justify-content-center gap-3">
														<div className=" button_home_brand">
															<button
																type="button"
																className="sec_btn"
																onClick={(e) => {
																	if (userInfo?.is2fa_enabled && !twoFaSetting.isClicked) {
																		setTwoFaSetting((prev) => ({
																			...prev,
																			isClicked: true
																		}));
																		return;
																	}
																	setIsAddBrand((prev) => !prev);
																}}
																// disabled={isDisabled}
															>
																{isAddBrand ? "Cancel" : "Add Brand"}
															</button>
														</div>

														{!isAddBrand && (
															<div className="button_home_brand">
																<button
																	type="button"
																	className="sec_btn"
																	onClick={async () => await brandSubmitHandler("DELETE")}
																	// disabled={isDisabled}
																>
																	Delete Brand
																</button>
															</div>
														)}

														<div className="button_home_brand">
															<button
																type="button"
																className="sec_btn"
																onClick={async () => {
																	if (isAddBrand) {
																		await brandSubmitHandler("CREATE");
																	} else {
																		await brandSubmitHandler("UPDATE");
																	}
																}}
																// disabled={isDisabled}
															>
																{isAddBrand ? "Publish Brand" : "Update Brand"}
															</button>
														</div>
													</div>
												</>
											)}
										</Col>
									</Row>
								</Col>
							</Row>

							{/* Create Brand Title */}
							{isAddBrand && (
								<Row>
									<div className="mb_10">
										<h4>Create Brand</h4>
									</div>
								</Row>
							)}

							<Row>
								<Col lg={4}>
									<InputField
										label="Brand"
										labelIcon={<MdOutlineCasino size={20} />}
										type="text"
										name="brand"
										value={formData.brand}
										onChange={(e) => handleFormData("brand", e.target.value)}
										errorMessage={formData?.error?.brand}
									/>
								</Col>
								<Col lg={4}>
									<div className="form_input_wp">
										<label className="form_input_label">
											<div className="form_input_icon">
												<MdOutlineCasino size={20} />
											</div>
											Invoice Currency
										</label>
										<DropDown
											placeholder="Select Currency"
											options={currencies}
											dataKey="currency_abrv"
											isSelected={formData?.invoiceCurrency}
											activeOption={formData?.invoiceCurrency}
											activeOptionId={formData?.invoiceCurrency}
											setOption={(data, dataIndex) => {
												handleFormData("invoiceCurrency", data?.currency_abrv);
											}}
										/>
									</div>
								</Col>
								<Col lg={4}>
									<InputField
										type="number"
										label="Due Date (In Days)"
										labelIcon={<MdOutlineCasino size={20} />}
										name="due_date"
										max={999}
										min={1}
										step={1}
										value={formData.dueDate}
										onChange={(e) => handleFormData("dueDate", e.target.value)}
									/>
								</Col>

								<Col lg={3}>
									<InputField
										label="Billing Email"
										labelIcon={<MdOutlineCasino size={20} />}
										type="text"
										name="billing_email"
										value={formData.billing_email}
										onChange={(e) => handleFormData("billing_email", e.target.value)}
										errorMessage={formData?.error?.billing_email}
									/>
								</Col>
								<Col lg={3}>
									<InputField
										label="Tax Rate (%)"
										labelIcon={<MdOutlineCasino size={20} />}
										type="number"
										step={0.01}
										name="tax_rate"
										value={formData.taxRate}
										onChange={(e) => handleFormData("taxRate", e.target.value)}
									/>
								</Col>

								<Col lg={3}>
									<InputField
										type="number"
										label="Royalties Slots (%)"
										labelIcon={<MdOutlineCasino size={20} />}
										name="royalties"
										min={0}
										max={100}
										step={0.01}
										value={formData.royalties}
										onChange={(e) => handleFormData("royalties", e.target.value)}
									/>
								</Col>

								<Col lg={3}>
									<label className="form_input_label">
										<div className="form_input_icon">
											<MdOutlineCasino size={20} />
										</div>
										Royalties Game Types
									</label>
									<div className="button_home_brand">
										<button
											type="button"
											className="sec_btn white-btn w-100"
											onClick={() => setBonusDeductionGamesModal(true)}

											// disabled={isDisabled}
										>
											Royalties Options <IoMdOpen />
										</button>

										<MyVerticallyCenteredModal
											show={bonusDeductionGamesModal}
											onHide={() => setBonusDeductionGamesModal(false)}
											title="Royalties Game Types"
										>
											<RoyaltiesGameTypes
												// defaultValues={formData?.royalties_applicable_game_types}
												setInputFields={setInputFields}
												inputFields={inputFields}
												handleFormData={handleFormData}
												gameTypes={gameTypes}
												setBonusDeductionGamesModal={setBonusDeductionGamesModal}
											/>
										</MyVerticallyCenteredModal>
									</div>
								</Col>

								<Col lg={3}>
									<InputField
										type="number"
										label="Bonus Deduction (%)"
										labelIcon={<MdOutlineCasino size={20} />}
										name="bonus_deduction"
										min={0}
										max={100}
										step={0.01}
										value={formData.bonusDeduction}
										onChange={(e) => handleFormData("bonusDeduction", e.target.value)}
									/>
								</Col>
								<Col lg={6}>
									<label className="form_input_label">
										<div className="form_input_icon">
											<MdOutlineCasino size={20} />
										</div>
										Bonus Deduction Period
									</label>
									<Select
										className="form__input-select"
										isMulti
										value={
											formData?.bonus_applicable_months?.length
												? formData?.bonus_applicable_months?.split(",")?.map((item) => {
														return { value: item, label: item };
												  })
												: []
										}
										theme={(theme) => ({
											...theme,
											borderRadius: 0,

											colors: {
												...theme.colors,
												primary25: "#8651d6",
												primary: "#ffffff",
												primary50: "#6f44b1"
											}
										})}
										onChange={(value) => handleFormData("bonus_applicable_months", value.map((item) => item.value).join(","))}
										options={monthList}
									/>
								</Col>

								<Col lg={3}>
									<label className="form_input_label">
										<div className="form_input_icon">
											<MdOutlineCasino size={20} />
										</div>
										Bonus Deduction Games
									</label>

									<MultiSelectDropDown
										options={allGames}
										placeholder="Select Games"
										activeOptions={
											formData.bonus_applicable_games?.length
												? formData?.bonus_applicable_games?.split(",")?.map((item) => {
														return { value: item, label: allGames.find((el) => el.value == item)?.label || item };
												  })
												: []
										}
										selectedLabel="Games Selected"
										dataKey="label"
										setSelectedOptions={(data) => {
											const selectedValues = data.map((item) => item.value).join(",");
											handleFormData("bonus_applicable_games", selectedValues);
										}}
									/>
								</Col>

								<Col lg={4}>
									<InputField
										type="textarea"
										label="Invoice from"
										labelIcon={<MdOutlineCasino size={20} />}
										name="invoice_from"
										value={formData.invoiceFrom}
										onChange={(e) => handleFormData("invoiceFrom", e.target.value)}
									/>
								</Col>
								<Col lg={4}>
									<InputField
										type="textarea"
										label="Bill To"
										labelIcon={<MdOutlineCasino size={20} />}
										name="bill_to"
										value={formData.billTo}
										onChange={(e) => handleFormData("billTo", e.target.value)}
									/>
								</Col>
								<Col lg={4}>
									<InputField
										type="textarea"
										label="Bank Account or Wallet Address"
										labelIcon={<MdOutlineCasino size={20} />}
										name="bank_account_or_wallet_address"
										value={formData.bankAccountOrWalletAddress}
										onChange={(e) => handleFormData("bankAccountOrWalletAddress", e.target.value)}
									/>
								</Col>
							</Row>
						</div>
					)}
				</div>
			</div>
		</form>
	);
};

export default BrandSettings;
