import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RiLoader4Line } from "react-icons/ri";
import { UserInfoState } from "../../context/UserInformationContext";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/images/logo.png";

const Login = () => {
    const { getUserInfo } = UserInfoState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [Loader, setLoader] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user-authorization-token"))) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem("userLogin")) {
            setRememberMe(true);
            setUsername(JSON.parse(localStorage.getItem("userLogin"))?.username);
        }
    }, []);

    const handleRemberMe = (event) => {
        const input = event.target;
        const value = input.type === "checkbox" ? input.checked : input.value;
        setRememberMe(value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError("");
        if (!username || !password) {
            setError("Please enter username and password");
            return;
        } else {
            if (!username) {
                setError("Please enter username");
                return;
            }
            if (!password) {
                setError("Please enter password");
                return;
            }
        }
        setLoader(true);
        axios
            .post(
                `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/login.php`,
                { username, password },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "POST",
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then(async (response) => {
                console.log(response)
                if (response?.data?.status) {
                    localStorage.setItem(
                        "user-authorization-token",
                        JSON.stringify({
                            userid: response.data.userid,
                            token: response.data.token,
                        })
                    );
                    await getUserInfo(
                        response.data.userid,
                        response.data.token,
                        'login'
                    );
                    navigate("/");
                } else {
                    setError(response.data?.error);
                    localStorage.clear();
                    navigate("/login");
                }
                // if (response.data?.userid) {
                //     if (rememberMe) {
                //         localStorage.setItem(
                //             "userLogin",
                //             JSON.stringify({
                //                 userid: response.data.userid,
                //                 username,
                //             })
                //         );
                //     } else {
                //         localStorage.removeItem("userLogin");
                //     }
                //     await getUserInfo(response.data.userid);
                //     navigate("/");
                // } else {
                //     setError(response.data?.error);
                // }
            })
            .catch((error) => {
                setError(error?.message);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <section className="login_form_sec">
            <Container>
                <Row>
                    <Col lg={6} className="m-auto">
                        <div className="text-center mb_30">
                            <img src={logo} alt="Player Reporting" width={220} height={74} />
                        </div>
                        <form className="login_form white_form" onSubmit={handleFormSubmit}>
                            <div className="form_shape"></div>
                            <div className="login_form_title">
                                <h2 className="h2_title">Let's Get Started</h2>
                                <p>Sign in to continue to your dashboard.</p>
                            </div>

                            <div className="form_input_wp">
                                <input
                                    type="text"
                                    className={`form_input ${error ? "error_input" : ""}`}
                                    placeholder="Email Id or username"
                                    name="casinoId"
                                    value={username ?? ""}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="form_input_wp">
                                <input
                                    type="password"
                                    className={`form_input ${error ? "error_input" : ""}`}
                                    placeholder="password"
                                    name="password"
                                    value={password ?? ""}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="form_input_wp checkbox_wp">
                                <input type="checkbox" name="checkbox" id="checkbox" checked={rememberMe} onClick={handleRemberMe} />
                                <label htmlFor="checkbox">Remember Me</label>
                            </div>
                            <div className="form_submit mb_20">
                                <button type="submit" className="sec_btn">
                                    Sign In
                                </button>
                                {Loader ? <RiLoader4Line size={25} /> : ""}
                            </div>
                            <p className="form_submit_error">{error}</p>
                            <div className="form_contact">
                                <p>
                                    Don't have an account? &nbsp;
                                    <a href="https://urgentgames.com/" title="Contact Us" target="blank">
                                        Contact Us
                                    </a>
                                </p>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Login;
