import React, { useEffect,  useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { BsCalendar2Date } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GiCreditsCurrency } from "react-icons/gi";
import DateRangePicker from "react-bootstrap-daterangepicker";
import FinancialGameReports from "../../components/FiancialReports/GameReports";
import FinancialGameTypeReports from "../../components/FiancialReports/GameTypeReports";
import DropDown from "../../components/Common/DropDown";

moment.locale("utc");

const FinancialReports = () => {
    const [selectedCurrency, setSelectedCurrency] = useState("All");
    const [currencies, setCurrencies] = useState([]);
    const [refreshToggle, setRefreshToggle] = useState(false);
    const [width, setWidth] = useState();
    const [dropDown, setDropdown] = useState(false);
    const [state, setState] = useState({
        start: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
    });
    const { start, end } = state;

    const handleCallback = (start, end) => {
        setState({ start, end });
        if (start && end !== null) {
            setRefreshToggle((prev) => !prev);
        }
    };

    // render currency list
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/currencies/list?token=${
                    JSON.parse(localStorage.getItem("currentCasino"))?.casino_token
                }`
            )
            .then((response) => {
                if (response?.data?.status === 200) {
                    setCurrencies([{ currency_abrv: "All" }, ...response?.data?.data]);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(error.response?.data?.message, {
                        theme: "dark",
                        autoClose: 1500,
                    });
                } else {
                    toast.error(error.message, { theme: "dark", autoClose: 1500 });
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectCurrency = (currency) => {
        setSelectedCurrency(currency);
    };

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className="form_box_wp casino_chart mb_30">
                <div className="datepicker_wp mb_30">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="date_picker_wrapper">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <BsCalendar2Date size={20} />
                                    </div>
                                    Select Date
                                </label>

                                <DateRangePicker
                                    initialSettings={{
                                        startDate: moment().startOf("month").toDate(),
                                        endDate: moment().endOf("month").toDate(),
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, "days").toDate(),
                                                moment().subtract(1, "days").toDate(),
                                            ],
                                            "Last 7 Days": [
                                                moment().subtract(6, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "Last 30 Days": [
                                                moment().subtract(29, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "This Month": [
                                                moment().startOf("month").toDate(),
                                                moment().endOf("month").toDate(),
                                            ],
                                            "Last Month": [
                                                moment()
                                                    .subtract(1, "month")
                                                    .startOf("month")
                                                    .toDate(),
                                                moment().subtract(1, "month").endOf("month").toDate(),
                                            ],
                                        },
                                    }}
                                    onCallback={handleCallback}>
                                    <input type="text" className="form_input" />
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="col-lg-5"></div>
                        <div className="col-lg-3">
                            <div className="datepicker_right">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <GiCreditsCurrency size={20} />
                                    </div>
                                    Select Currency
                                </label>
                                <DropDown
                                    placeholder="Select Currency"
                                    options={currencies}
                                    dataKey="currency_abrv"
                                    isSelected={selectedCurrency}
                                    activeOption={selectedCurrency}
                                    activeOptionId={selectedCurrency}
                                    setOption={(data, index) => {
                                        setSelectedCurrency(data?.currency_abrv);
                                    }}
                                />
                                {/* <div
                                    className={`dropdown_wp currency_dropdown ${
                                        dropDown ? "active_dropdown" : ""
                                    }`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setDropdown(false)}>
                                        {selectedCurrency ? selectedCurrency : ""}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {currencies?.map((currency) => (
                                            <li
                                                key={currency.currency_abrv}
                                                onClick={() => {
                                                    selectCurrency(currency.currency_abrv);
                                                }}>
                                                {currency?.currency_abrv}
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="table_loader_wp casino_chart form_box_wp mb_30">
                <FinancialGameReports
                    startDate={moment(start).format("YYYY-MM-DD")}
                    endDate={moment(end).format("YYYY-MM-DD")}
                    currency={selectedCurrency !== "All" ? selectedCurrency : ""}
                    refreshToggle={refreshToggle}
                    width={width}
                    accessType="admin"
                />
            </div>

            <div className="table_loader_wp casino_chart form_box_wp mb_30">
                <FinancialGameTypeReports
                    startDate={moment(start).format("YYYY-MM-DD")}
                    endDate={moment(end).format("YYYY-MM-DD")}
                    currency={selectedCurrency !== "All" ? selectedCurrency : ""}
                    refreshToggle={refreshToggle}
                    width={width}
                />
            </div>
        </>
    );
};

export default FinancialReports;
