import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    // const userdata = JSON.parse(localStorage.getItem("userInformation"));
    const userdata = JSON.parse(localStorage.getItem("user-authorization-token"));

    if (location.pathname === "/login" && userdata) {
        return <Navigate to="/" />;
    }

    if (!userdata) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default PrivateRoute;
