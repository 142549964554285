import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../../../components/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BsCalendar2Date } from "react-icons/bs";
import { LuUser2 } from "react-icons/lu";
import InputField from "../../../components/Common/InputField";

const CasinoTable = () => {
    const [state, setState] = useState({
        start: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
    });

    const [searchBoxValue, setSearchBoxValue] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [limit, setLimit] = useState(10);

    const [refreshToggle, setRefreshToggle] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
    };

    //* Set Table Dark Theme  /
    createTheme(
        "solarized",
        {
            text: {
                primary: "#ffffff",
                secondary: "#8651d6",
            },
            background: {
                default: "rgb(22 22 22 / 20%)",
            },
            context: {
                background: "#cb4b16",
                text: "#FFFFFF",
            },
            divider: {
                default: "rgba(225,225,225,0.08)",
            },
            pagination: {
                fontSize: "1rem",
                fontFamily: "inherit",
                textAlign: "right",
            },
        },
        "dark"
    );

    /* Set Table Column  */
    const columns = [
        {
            name: "Player",
            selector: (row) => row.remote_id || `${row.fname} ${row.lname}`,
            sortable: true,
        },
        {
            name: "Created At",
            selector: (row) => moment(row.created_at).format("YYYY-MM-DD HH:mm:ss") || "",
            sortable: true,
        },
        {
            name: "RoundId",
            selector: (row) => row.round_id,
            sortable: true,
        },
        {
            name: "Game Name",
            selector: (row) => (row.name ? row.name : row.game_id),
            sortable: true,
        },
    ];

    const onSubmitCartData = (e) => {
        e.preventDefault();
        setRefreshToggle((prev) => !prev);
    };

    /* Data Fetch Function   */
    const fetchRounds = async (page, limitValue = limit) => {
        setLoading(true);
        setErrorMessage("");

        const currentCasino = JSON.parse(localStorage?.getItem("currentCasino"));
        await axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/reports/transactions/game-rounds?token=${
                    currentCasino?.casino_token
                }&report=transactions&casino=${
                    currentCasino?.casino_key
                }&page=${page}&limit=${limitValue}&start=${moment(start).format(
                    "YYYY-MM-DD"
                )}T00:00:00Z&end=${moment(end).format("YYYY-MM-DD")}T23:59:59Z&searchValue=${searchBoxValue}`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    if (response.data?.response?.rounds?.length === 0) {
                        setErrorMessage("There are no record found");
                    }
                    setData(response.data?.response?.rounds);
                } else {
                    setErrorMessage(response?.data?.response);
                }
            })
            .catch((error) => {
                if (error.response) {
                    setErrorMessage(error.response?.data?.message);
                } else {
                    setErrorMessage(error.message);
                }
            })
            .finally(() => {
                setLoading(false);
                setLoading(false);
            });
    };

    // const handlePerRowsChange = async (limitValue, page) => {
    //     setLimit(limitValue);
    //     setLimit(limitValue);
    //     fetchRounds(page, limitValue);
    // };

    useEffect(() => {
        fetchRounds(1, limit); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshToggle]);

    const RoundInfoComponent = ({ data }) => {
        return (
            <div>
                {data ? (
                    <pre>
                        Bet: {data.bet ? JSON.stringify(data.bet, null, 2) : ""}
                        {"\n"}
                        Game End: {data.game_end ? JSON.stringify(data.game_end, null, 2) : ""}
                        {"\n"}
                        Bonus Rounds: {data.bonus_rounds ? JSON.stringify(data.bonus_rounds, null, 2) : ""}
                        {"\n"}
                        Game Round Over:{" "}
                        {data.game_round_over ? JSON.stringify(data.game_round_over, null, 2) : ""}
                    </pre>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        );
    };

    // const CustomPagination = ({ pagination, rowsPerPage, ...props }) => {
    //     console.log('pagination', pagination)
    //    return(<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '20px' }}>
    //         <button onClick={() => pagination.onNextPage()}>
    //             Next
    //         </button>
    //     </div>)
    // };

    const onNextPage = () => {
        setCurrentPage(currentPage + 1);
        fetchRounds(currentPage + 1, limit);
    };

    const onPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            fetchRounds(currentPage + 1, limit);
        }
    };

    return (
        <>
            <div className="form_box_wp mb_30">
                <div className="player_reports_table">
                    <div className="table_loader_wp casino_game_rounds_wp" style={{ minHeight: "90px" }}>
                        <form onSubmit={onSubmitCartData}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form_input_wp">
                                        <label className="form_input_label">
                                            <div className="form_input_icon">
                                                <BsCalendar2Date size={20} />
                                            </div>
                                            Select Date
                                        </label>

                                        <DateRangePicker
                                            initialSettings={{
                                                startDate: moment().startOf("month").toDate(),
                                                endDate: moment().endOf("month").toDate(),
                                                ranges: {
                                                    Today: [moment().toDate(), moment().toDate()],
                                                    Yesterday: [
                                                        moment().subtract(1, "days").toDate(),
                                                        moment().subtract(1, "days").toDate(),
                                                    ],
                                                    "Last 7 Days": [
                                                        moment().subtract(6, "days").toDate(),
                                                        moment().toDate(),
                                                    ],
                                                    "Last 30 Days": [
                                                        moment().subtract(29, "days").toDate(),
                                                        moment().toDate(),
                                                    ],
                                                    "This Month": [
                                                        moment().startOf("month").toDate(),
                                                        moment().endOf("month").toDate(),
                                                    ],
                                                    "Last Month": [
                                                        moment()
                                                            .subtract(1, "month")
                                                            .startOf("month")
                                                            .toDate(),
                                                        moment().subtract(1, "month").endOf("month").toDate(),
                                                    ],
                                                },
                                            }}
                                            onCallback={handleCallback}>
                                            <input type="text" className="form_input" />
                                        </DateRangePicker>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <InputField
                                        type="text"
                                        label="Player Or RoundId"
                                        labelIcon={<LuUser2 size={20} />}
                                        placeholder="Enter Player or RoundId"
                                        name="search_box_value"
                                        onChange={(event) => setSearchBoxValue(event.target.value)}
                                        value={searchBoxValue || searchBoxValue}
                                    
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <button type="submit" className="sec_btn">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>

                        <h5 className="h5_title text-center mb-3">Rounds History</h5>
                        {errorMessage ? (
                            <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
                        ) : (
                            <>
                                {data.length > 0 && (
                                    <>
                                        <DataTable
                                            theme="solarized"
                                            columns={columns}
                                            data={data}
                                            expandableRows
                                            expandableRowsComponent={RoundInfoComponent}
                                        />

                                        {!searchBoxValue && (
                                            <>
                                                <div className="pagination">
                                                    {currentPage > 1 && (
                                                        <button
                                                            onClick={onPreviousPage}
                                                            className="sec_btn sm_btn">
                                                            Previous
                                                        </button>
                                                    )}
                                                    <button onClick={onNextPage} className="sec_btn sm_btn">
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {loading ? <Loader /> : ""}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CasinoTable;
