import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import PrivateRoute from "./components/PrivateRoute";
import { PrivateRouteData } from "./components/PrivateRoute/PrivateRouteData";
import { UserInfoState } from "./context/UserInformationContext";
import Loader from "./components/Loader";

const App = () => {
    const [sideBar, setSideBar] = useState(false);
    const [mobileHeader, setMobileHeader] = useState(false);
    const { getUserInfo, accessiblePages, isLoading } = UserInfoState();

    const sideBarButton = () => {
        setSideBar(!sideBar);
    };

    const header_menu_button = () => {
        setMobileHeader(!mobileHeader);
    };

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("userInformation"))?.casino_information !== null) {
            if (JSON.parse(localStorage.getItem("userInformation"))?.casino_information && JSON.parse(localStorage.getItem("userInformation"))?.casino_information?.length > 0) {
                const casinoDetails = localStorage?.getItem("currentCasino");
                if (casinoDetails === null) {
                    const casinoInfo = JSON.parse(localStorage.getItem("userInformation"))?.casino_information;
                    localStorage?.setItem("currentCasino", JSON?.stringify(casinoInfo[0]));
                }
            }
        }

        if (JSON.parse(localStorage.getItem("user-authorization-token"))) {
            getUserInfo(
                JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
                JSON.parse(localStorage.getItem("user-authorization-token"))?.token,
            );
        }
    }, []);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />

                    {PrivateRouteData.map((data, index) => {
                        return (
                            <React.Fragment key={data.menu_num}>
                                <Route
                                    path={data.path}
                                    element={
                                        <PrivateRoute>
                                            <div
                                                className={
                                                    sideBar
                                                        ? `page_wrapper sidebar_collapsed ${mobileHeader ? "sidebar_collapsed" : ""
                                                        }`
                                                        : `page_wrapper ${mobileHeader ? "sidebar_collapsed" : ""
                                                        }`
                                                }>
                                                <SideBar
                                                    sideBar={sideBar}
                                                    mobileHeader={mobileHeader}
                                                    header_menu_button={header_menu_button}
                                                />
                                                <main className="main_content">
                                                    <Header
                                                        mobileHeader={mobileHeader}
                                                        header_menu_button={header_menu_button}
                                                        sideBarButton={sideBarButton}
                                                        sideBar={sideBar}
                                                    />
                                                    <div
                                                        className="table_loader_wp"
                                                        style={{ minHeight: "70px" }}>
                                                        {isLoading ? (
                                                            <Loader />
                                                        ) : (
                                                            <>
                                                                {data.hasOwnProperty("page_name") ? (
                                                                    accessiblePages?.includes(
                                                                        data?.page_name
                                                                    ) ? (
                                                                        <div className="page_content">
                                                                            {data.components}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="not_access_page">
                                                                            <h4>
                                                                                Sorry, you can&apos;t access
                                                                                this page.
                                                                            </h4>
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    <div className="page_content">
                                                                        {data.components}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </main>
                                            </div>
                                        </PrivateRoute>
                                    }
                                />
                            </React.Fragment>
                        );
                    })}

                    <Route path="*" element={<p>Not Found</p>} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
