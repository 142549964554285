import React from 'react'

const InputField = ({ value, label, name, id, placeholder, type, cols, rows, onChange, className, readOnly, max, min, step, required, onInput, labelIcon, errorMessage, register, validationSchema }) => {
    return (
        <>
            <div className="form_input_wp">

                {label && <label className="form_input_label">
                    {labelIcon && <div className="form_input_icon">
                        {labelIcon}
                    </div>}
                    {label}
                </label>}

                {type === "textarea" ?
                    <textarea
                        id={id}
                        placeholder={placeholder}
                        className={className ? className : 'form_input'}
                        value={value}
                        onChange={onChange}
                        name={name}
                        cols={cols ? cols : "30"}
                        rows={rows ? rows : "10"}
                        readOnly={readOnly && readOnly}
                        required={required && required}
                        {...(register && { ...register(name, validationSchema && validationSchema) })}
                    />

                    : <input
                        id={id}
                        placeholder={placeholder}
                        className={className ? className : 'form_input'}
                        type={type}
                        value={value}
                        onChange={onChange}
                        name={name}
                        readOnly={readOnly && readOnly}
                        min={min && min}
                        max={max && max}
                        step={step && step}
                        required={required && required}
                        onInput={onInput}
                        {...(register && { ...register(name, validationSchema && validationSchema) })}
                    />}

                {errorMessage && <p style={{ color: "red" }}>
                    {errorMessage}
                </p>}

            </div>
        </>
    )
}

export default InputField