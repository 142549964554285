import React, { useEffect, useState } from "react";
import DropDown from "../Common/DropDown";
import InputField from "../Common/InputField";
import { MdOutlineCasino } from "react-icons/md";

const RoyaltiesGameTypes = ({ gameTypes, handleFormData, inputFields, setInputFields, setBonusDeductionGamesModal }) => {
	const [gameTypesOptions, setGameTypesOption] = useState(gameTypes);
	const addInputField = () => {
		setInputFields([
			...inputFields,
			{
				gameType: "",
				royalties: ""
			}
		]);
	};

	const removeInputFields = (index) => {
		const rows = [...inputFields];
		rows.splice(index, 1);
		setInputFields(rows);
	};

	const handleChange = (index, e) => {
		const { name, value } = e.target;
		const list = [...inputFields];
		list[index][name] = value;
		setInputFields(list);
		const selectedGameType = list?.map((item) => item.gameType?.value);
		setGameTypesOption(gameTypes.filter((item) => !selectedGameType?.includes(item.value)));
	};

	const onsubmit = (e) => {
		e.preventDefault();
		const inputValues = inputFields.map((item) => ({ gameType: item.gameType.value, royalties: item.royalties }));
		handleFormData("royalties_applicable_game_types", JSON.stringify(inputValues));
		setBonusDeductionGamesModal(false);
	};

	useEffect(() => {
		const selectedGameType = inputFields?.map((item) => item.gameType?.value);
		setGameTypesOption(gameTypes.filter((item) => !selectedGameType?.includes(item.value)));
	}, [inputFields]);

	return (
		<>
			<form
				method="post"
				onSubmit={onsubmit}
			>
				<div className="">
					{inputFields?.map((data, index) => {
						const { gameType, royalties } = data;
						return (
							<div
								key={index}
								className="bonus_deduction_games_form"
							>
								<div className="form-group w-100 bonus_deduction_games_form_wp">
									<div className="form_input_wp">
										<label className="form_input_label">
											<div className="form_input_icon">
												<MdOutlineCasino size={20} />
											</div>
											Select Game Type
										</label>
										<DropDown
											className="form_input"
											placeholder="Select Game Type"
											options={gameTypesOptions}
											dataKey="value"
											isSelected={gameType?.label}
											activeOption={gameType?.label}
											activeOptionId={gameType?.label}
											setOption={(data, i) => {
												handleChange(index, { target: { name: "gameType", value: data } });
											}}
										/>
									</div>
									<InputField
										type="number"
										label="Royalties (%)"
										labelIcon={<MdOutlineCasino size={20} />}
										name="royalties"
										min={0}
										step={0.01}
										max={100}
										onChange={(e) => handleChange(index, e)}
										value={royalties}
									/>
								</div>

								<button
									type="button"
									className="sec_btn"
									onClick={() => removeInputFields(index)}
								>
									x
								</button>
							</div>
						);
					})}
				</div>
				{gameTypesOptions?.length && (
					<div className="d-flex w-100 justify-content-sm-end justify-content-center mb-4">
						<button
							className="sec_btn"
							type="button"
							onClick={addInputField}
							disabled={inputFields.filter((item) => !item.gameType || !item.royalties).length}
						>
							Add Royalty
						</button>
					</div>
				)}
				<div className="d-flex gap-4 justify-content-sm-end justify-content-center w-100">
					<button
						onClick={() => setBonusDeductionGamesModal(false)}
						className="sec_btn"
					>
						Close
					</button>
					<button
						type="submit"
						className="sec_btn"
					>
						Save
					</button>
				</div>
			</form>
		</>
	);
};

export default RoyaltiesGameTypes;
