import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

export default function MultiSelectDropDown({ activeOptions, setSelectedOptions, options, dataKey = "", placeholder, optionModify, dropDownOpenTop, disableAll }) {
	const [popularDropDown, setPopularDropdown] = useState(false);
	const [searched, setSearched] = useState("");
	const dropDownRef = useRef();
	const inputRef = useRef();
	const [selectAll, setSelectAll] = useState(false);

	useEffect(() => {
		if (popularDropDown) {
			inputRef.current.focus();
		} else {
			inputRef.current.blur();
		}
	}, [popularDropDown]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
				setPopularDropdown(false);
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const handleOptionToggle = (option, index) => {
		const updatedOptions = activeOptions ? [...activeOptions] : [];
		const optionIndex = updatedOptions.findIndex((opt) => opt[dataKey] === option[dataKey]);
		if (option.value == "ALL") {
			if (selectAll) {
				setSelectedOptions([]);
			} else {
				setSelectedOptions(options, index);
			}
			setPopularDropdown(false);
		} else {
			if (optionIndex !== -1) {
				updatedOptions.splice(optionIndex, 1); // Remove if already selected
			} else {
				updatedOptions.push(option); // Add if not selected
			}

			setSelectedOptions(updatedOptions, index);
		}
	};

	useEffect(() => {
		activeOptions?.length === options?.length ? setSelectAll(true) : setSelectAll(false);
	}, [activeOptions]);

	const selectedOptionsDisplay = () => {
		if (activeOptions) {
			if (activeOptions.length === 0) {
				return placeholder;
			} else if (activeOptions.length === 1) {
				const option = activeOptions[0];
				return option[dataKey];
			} else {
				return `${activeOptions.length} Selected`;
			}
		} else {
			return placeholder;
		}
	};

	return (
		<div
			className={`dropdown_wp currency_dropdown multi_select_dropdown ${dropDownOpenTop ? "dropdown_open_top" : ""}
                ${popularDropDown ? "active_dropdown" : ""}`}
			ref={dropDownRef}
		>
			<button
				type="button"
				className="sec_btn dropdown_btn transparent_btn"
				onClick={() => setPopularDropdown((prev) => !prev)}
			>
				{activeOptions && activeOptions.length === 0 ? placeholder : selectedOptionsDisplay()}
				<span className="dropdown_icon">
					<MdOutlineKeyboardArrowDown size={22} />
				</span>
			</button>

			<div className="dropdown_content ">
				<div className="dropdown_search">
					<IoSearch size={26} />
					<input
						ref={inputRef}
						type="search"
						className="form_input"
						placeholder="Search.."
						autoFocus
						onChange={(e) => setSearched(e.target.value.trim())}
					/>
				</div>

				<ul className="dropdown_list">
					{!disableAll && (!searched || "all".includes(searched.toLowerCase())) && (
						<>
							<li
								className={`${selectAll ? "active-option" : ""}`}
								onClick={() => {
									handleOptionToggle({ value: "ALL" });
									setSelectAll(!selectAll);
								}}
							>
								All
								{selectAll && <FaCheck size={18} />}
							</li>
							<li className="multi_select_divider"></li>
						</>
					)}
					{options?.map((data, index) => {
						let dataKeyOption, activeOptionClass;

						if (dataKey.length || !Array.isArray(options)) {
							dataKeyOption = data[dataKey];
							if (optionModify?.prefix && optionModify?.target === "index") {
								dataKeyOption = `${optionModify?.prefix}${index}`;
							}
							activeOptionClass = activeOptions && activeOptions.find((opt) => opt[dataKey] === data[dataKey]) ? "active-option" : "";
						} else {
							dataKeyOption = data;
							if (optionModify?.prefix && optionModify?.target === "index") {
								dataKeyOption = `${optionModify?.prefix}${index}`;
							}
							activeOptionClass = activeOptions && activeOptions.includes(data) ? "active-option" : "";
						}

						if (searched.length && !dataKeyOption?.toString().toLowerCase().includes(searched.toLocaleLowerCase())) {
							return null;
						}

						return (
							<li
								className={activeOptionClass}
								key={dataKeyOption + Math.random()}
								onClick={() => handleOptionToggle(data, index)}
							>
								{dataKeyOption}
								{activeOptionClass && <FaCheck size={18} />}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}
