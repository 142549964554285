import React from "react";
import PlayerTable from "./PlayerTable/index";

const Player = () => {
    return (
        <>
            <PlayerTable />
        </>
    );
};

export default Player;
