import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoCloudDownloadOutline } from "react-icons/io5";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../../components/Loader";

const CasinoSetting = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const currentCasino = JSON.parse(localStorage.getItem("currentCasino"));
    const [errorMessage, setErrorMessage] = useState("");
    const [limit, setLimit] = useState(10);

    /** Set dark theme in table  */
    createTheme(
        "solarized",
        {
            text: {
                primary: "#ffffff",
                secondary: "#8651d6",
            },
            background: {
                default: "rgb(22 22 22 / 20%)",
            },
            context: {
                background: "#cb4b16",
                text: "#FFFFFF",
            },
            divider: {
                default: "rgba(225,225,225,0.08)",
            },
        },
        "dark"
    );

    /*** Set Column in table */
    const columns = [
        {
            name: "Game Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Game Provider",
            selector: (row) => row.gameType,
            sortable: true,
        },
        {
            name: "RTP",
            selector: (row) => {
                return row.rtp ? row.rtp + " % " : "";
            },
            sortable: false,
        },
        {
            name: "Bonuses",
            selector: (row) => {
                return row.gameType ? 0 : "";
            },
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => {
                return (
                    <>
                        {row.gameType ? (
                            <input
                                type="checkbox"
                                id={row.id}
                                value={!row.disabledByCasino}
                                checked={row.disabledByCasino}
                                data-val={row.disabledByCasino}
                                onClick={() => {
                                    changeStatus(row.id, row.disabledByCasino ? false : true);
                                }}
                                // defaultChecked={!row.disabledByCasino}
                            />
                        ) : (
                            ""
                        )}
                    </>
                );
            },
            sortable: false,
            width: 50,
        },
        {
            name: "Downloads",
            selector: (row) => {
                return row.gameType ? (
                    <a href="https://portal.urgentgames.com/s/Marketing" title={"demo"} target={"blank"}>
                        <IoCloudDownloadOutline size={22} />
                    </a>
                ) : (
                    ""
                );
            },
            sortable: false,
        },
    ];

    /** Fetch Casino Details  */
    const getAvailablegame = async (page) => {
        setLoading(true);
        setPage(page);
        await axios
            .get(
                `${process.env.REACT_APP_GAME_SECURE}/games/admin?action=available_games&token=${currentCasino?.casino_token}&casino=${currentCasino?.casino_key}&limit=${limit}&page=${page}`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    setData(response.data.response);
                    setTotalRows(response.data.totalGamesCount);
                } else {
                    setErrorMessage(response?.data?.response);
                }
            })
            .catch(function (error) {
                if (error.response.data.error.status === 403) {
                    setErrorMessage(error.response.data.error.message);
                } else if (error?.message === "Request failed with status code 403") {
                    setErrorMessage("Unauthorized Toekn");
                } else {
                    setErrorMessage(error?.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /** enable & disable game  */
    const changeStatus = async (gameId, gameStatus) => {
        setLoading(true);
        await axios
            .get(
                `${process.env.REACT_APP_GAME_SECURE}/games/admin?action=${
                    !gameStatus ? "enable_game" : "disable_game"
                }&token=${currentCasino?.casino_token}&game_id=${gameId}&casino=${currentCasino?.casino_key}`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    getAvailablegame(page);
                } else {
                    setErrorMessage(response?.data?.response);
                }
            })
            .catch(function (error) {
                if (error.response.data.error.status === 403) {
                    setErrorMessage(error.response.data.error.message);
                } else if (error?.message === "Request failed with status code 403") {
                    setErrorMessage("Unauthorized Token");
                } else {
                    setErrorMessage(error?.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /** Change Page event */
    const handlePageChange = (page) => {
        getAvailablegame(page);
    };

    /** Custom Pagination HTML */
    // const BootyPagination = ({ onChangePage, currentPage }) => {
    //     const handleBackButtonClick = () => {
    //         onChangePage(currentPage - 1);
    //     };

    //     const handleNextButtonClick = () => {
    //         onChangePage(currentPage + 1);
    //     };

    //     const handlePageNumber = (e) => {
    //         onChangePage(Number(e.target.value));
    //     };

    //     const nextDisabled = currentPage === totalPages;
    //     const previosDisabled = currentPage === 1;

    //     return (
    //         <div className="pagination_wp mt_30">
    //             <nav>
    //                 <ul className="pagination">
    //                     <li className="page-item">
    //                         <button
    //                             className="page-link"
    //                             onClick={handleBackButtonClick}
    //                             disabled={previosDisabled}
    //                             aria-disabled={previosDisabled}
    //                             aria-label="previous page">
    //                             <MdOutlineKeyboardArrowLeft size={22} />
    //                         </button>
    //                     </li>
    //                     {(() => {
    //                         const arr = [];
    //                         for (let i = 1; i <= totalPages; i++) {
    //                             const className = i === currentPage ? "page-item active" : "page-item";
    //                             arr.push(
    //                                 <li key={"pagination" + i} className={className}>
    //                                     <button className="page-link" onClick={handlePageNumber} value={i}>
    //                                         {i}
    //                                     </button>
    //                                 </li>
    //                             );
    //                         }
    //                         return arr;
    //                     })()}
    //                     <li className="page-item">
    //                         <button
    //                             className="page-link"
    //                             onClick={handleNextButtonClick}
    //                             disabled={nextDisabled}
    //                             aria-disabled={nextDisabled}
    //                             aria-label="next page">
    //                             <MdOutlineKeyboardArrowRight size={22} />
    //                         </button>
    //                     </li>
    //                 </ul>
    //             </nav>
    //         </div>
    //     );
    // };

    /** Call Default function  */
    useEffect(() => {
        getAvailablegame(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePerRowsChange = async (newPerPage, page) => {
        setLimit(newPerPage);
        setLoading(true);
        setPage(page);
        await axios
            .get(
                `${process.env.REACT_APP_GAME_SECURE}/games/admin?action=available_games&token=${currentCasino?.casino_token}&casino=${currentCasino?.casino_key}&limit=${newPerPage}&page=${page}`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    setData(response.data.response);
                    setTotalRows(response.data.totalGamesCount);
                } else {
                    setErrorMessage(response?.data?.response);
                }
            })
            .catch(function (error) {
                if (error.response.data.error.status === 403) {
                    setErrorMessage(error.response.data.error.message);
                } else if (error?.message === "Request failed with status code 403") {
                    setErrorMessage("Unauthorized Token");
                } else {
                    setErrorMessage(error?.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="player_reports_table">
            <div className="table_loader_wp" style={{ minHeight: "70px" }}>
                {errorMessage ? (
                    <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
                ) : (
                    <>
                        {data.length > 0 && (
                            <DataTable
                                theme="solarized"
                                columns={columns}
                                data={data}
                                pagination
                                paginationServer
                                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                // paginationComponent={BootyPagination}
                                paginationTotalRows={totalRows}
                                subHeader
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                            />
                        )}
                    </>
                )}
                {loading ? <Loader /> : ""}
            </div>
        </div>
    );
};

export default CasinoSetting;
