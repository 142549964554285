import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../Loader";
import { Col, Row } from "react-bootstrap";
import CurrenciesDropdown from "../Common/CurrenciesDropdown";
import { GiCreditsCurrency } from "react-icons/gi";
import DropDown from "../Common/DropDown";

const SlotsGGRChart = ({ startDate, endDate, currencies, refreshToggle, width }) => {
	const [chartdata, setChartdata] = useState({});
	const [monthlist, setMonthlist] = useState({ series: [], labels: [], original: { series: [], labels: [] }, disabledIndexes: [] });
	const [currency, setCurrency] = useState("All");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const getData = () => {
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));

		setLoading(true);
		setErrorMessage("");

		axios
			.get(
				`https://api.playerreporting.com/reports/transactions/dashboard/ggr-slots-games?token=${casino?.casino_token}&casino=${casino?.casino_key}&currency=${
					currency === "All" ? "" : currency
				}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&filterBy=All`
			)
			.then((response) => {
				if (response.data.status === 200) {
					// console.log(response.data?.reportForChartGGR)
					setChartdata(response.data?.reportForChartGGR);
				} else {
					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setErrorMessage("");
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle, currency]);

	useEffect(() => {
		if (Object.keys(chartdata).length) {
			const slotsGGRs = Object.keys(chartdata).map((game) => parseFloat(chartdata[game]?.GGR.toFixed(2)));
			setErrorMessage("");
			const chartLabel = Object.keys(chartdata)?.map((chart) => {
				return `${chart} (${Object.keys(chartdata[chart?.toString()]?.games)?.length} Games) `;
			});
			const modifiedData = { series: slotsGGRs, labels: chartLabel };
			setMonthlist((p) => ({ ...p, ...modifiedData, original: modifiedData }));
		} else {
			const emptyData = { series: [], labels: [] };
			setMonthlist((p) => ({ ...p, ...emptyData, original: { ...emptyData } }));
			setErrorMessage("There are no records to display");
		}
	}, [chartdata]);

	const chartProps = {
		series: monthlist.series,
		options: {
			chart: {
				width: "100%",
				type: "pie"
			},
			labels: monthlist.labels,
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex].split(" ")[0];
					return [name, parseFloat(val).toFixed(1) + "%"];
				}
			},
			legend: {
				horizontalAlign: "left",
				offsetX: 20,
				labels: {
					colors: "#fff"
				},
				markers: {
					offsetX: -3,
					offsetY: 2
				},
				itemMargin: {
					horizontal: 10
				}
			},
			responsive: [
				{
					breakpoint: 575,
					options: {
						legend: {
							position: "bottom"
						}
					}
				}
			]
		}
	};

	const toggleData = (index) => {
		setMonthlist((prev) => {
			if (!prev.disabledIndexes.includes(index)) {
				const disabledIndexes = [...prev.disabledIndexes, index];
				const newSeries = prev.original.series.filter((series, sIndex) => !disabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => !disabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: disabledIndexes };
			} else {
				let updatedDisabledIndexes = [...prev.disabledIndexes];

				updatedDisabledIndexes = updatedDisabledIndexes.filter((el) => el !== index);

				const newSeries = prev.original.series.filter((series, sIndex) => !updatedDisabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => !updatedDisabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: updatedDisabledIndexes };
			}

			return prev;
		});
	};

	return (
		<>
			<div className="finance_heapchart game_reports_charts mb_30">
				<Row className="align-items-center">
					<Col lg={6}>
						<h5 className="h5_title">Slots GGR</h5>
					</Col>
					<Col lg={6}>
						{/* <CurrenciesDropdown currencies={currencies} currency={currency} setCurrency={setCurrency} width={width} /> */}
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
					</Col>
				</Row>
			</div>

			{!loading && errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<>
					<Chart
						{...chartProps}
						type="pie"
						width="100%"
						height="500px"
					/>
					<div className="chart_options">
						{monthlist.original.labels?.map((label, index) => {
							const disabledLabel = monthlist.disabledIndexes.includes(index);
							return (
								<p
									onClick={() => toggleData(index)}
									className={disabledLabel ? "active_chart_option" : ""}
								>
									{label}
								</p>
							);
						})}
					</div>
				</>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default SlotsGGRChart;
