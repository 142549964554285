import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Loader from "../../Loader";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import CurrenciesDropdown from "../../Common/CurrenciesDropdown";
import DropDown from "../../Common/DropDown";
import { GiCreditsCurrency } from "react-icons/gi";
import MultiSelectDropDown from "../../Common/MultiSelectDropDown";

const AllCasinoGGR = ({ startDate, endDate, currencies, refreshToggle, width }) => {
	const [loading, setLoading] = useState(false);
	const [chartDate, setChartDate] = useState([]);
	const [xAxisList, setXAxisList] = useState([]);

	const [errorMessage, setErrorMessage] = useState("");
	const [currency, setCurrency] = useState("All");
	const [selectCasino, setSelectCasino] = useState([]);
	const [series, setSeries] = useState([]);

	const getData = () => {
		setErrorMessage("");
		setLoading(true);
		axios
			.get(`https://api.playerreporting.com/reports/transactions/dashboard/chart-admin?currency=${currency === "All" ? "" : currency}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z`)
			.then((response) => {
				if (response.data.status === 200) {
					setChartDate(response.data?.response?.daysOrMonths);

					let chartData = response.data?.response?.chartData;
					if (chartData.length === 0) {
						setErrorMessage("There are no records to display");
					}
					if (chartData.length) {
						chartData[0].currency = response.data?.response?.currency;
					}
					if (currency === "All") {
						chartData = chartData.map((row, index) => {
							return {
								...row,
								originalData: row.data,
								data: row.usdData
							};
						});
					}

					setXAxisList(chartData);
				} else {
					setChartDate([]);
					setXAxisList([]);

					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setChartDate([]);
				setXAxisList([]);

				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle, currency]);

	const dashboardChartOptions = {
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			width: 3,
			curve: "smooth"
		},
		title: {
			align: "center",
			style: {
				color: "#FEB019"
			}
		},
		xaxis: {
			categories: chartDate ? chartDate : [],
			labels: {
				style: {
					colors: "#ccc"
				}
			}
		},
		yaxis: [
			{
				labels: {
					formatter: (value) => {
						const currencyLabel = currency === "All" ? "Converted to USD" : xAxisList[0]?.currency;
						return `${parseFloat(value).toFixed(2)} ${!currencyLabel ? "" : currencyLabel}`;
					},
					style: {
						colors: "#ccc"
					}
				}
			}
		],
		tooltip: {
			theme: "dark",
			followCursor: true
		},
		legend: {
			position: "top",
			labels: {
				colors: "#fff"
			},
			markers: {
				offsetX: -4,
				offsetY: -1
			},
			itemMargin: {
				horizontal: 10
			}
		}
	};

	useEffect(() => {
		setSelectCasino(xAxisList);
	}, [xAxisList]);

	// Multiselect All option
	useEffect(() => {
		if (selectCasino.length > 0) {
			setSeries(selectCasino);
			setErrorMessage("");
		} else {
			setErrorMessage("There are no records to display");
		}
	}, [selectCasino]);

	return (
		<>
			<div className="finance_heapchart mb_30">
				<Row className="align-items-center">
					<Col lg={6}>
						<h5 className="h5_title">All Casinos GGR</h5>
					</Col>
					<Col lg={3}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Casinos
							</label>

							<MultiSelectDropDown
								placeholder="Select Casinos"
								options={xAxisList}
								isSelected={selectCasino}
								activeOptions={selectCasino}
								dataKey="name"
								setSelectedOptions={(data, index) => {
									console.log(data);
									setSelectCasino(data);
								}}
							/>
						</div>
					</Col>
					<Col lg={3}>
						{/* <CurrenciesDropdown
                            currencies={currencies}
                            currency={currency}
                            setCurrency={setCurrency}
                            width={width}
                        /> */}
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
					</Col>
				</Row>
			</div>

			{errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<Chart
					options={dashboardChartOptions}
					series={series}
					type="line"
					width="100%"
					height="500px"
				/>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default AllCasinoGGR;
