import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../Loader";
import { Col, Row } from "react-bootstrap";
import { GiCreditsCurrency } from "react-icons/gi";
import DropDown from "../Common/DropDown";

const SELECT_GAME = "Please select a game";

const BrandsGGRbyGameChart = ({ refreshToggle, width, startDate, endDate, currencies }) => {
	const [chartdata, setChartdata] = useState([]);
	const [optionsData, setOptionsData] = useState({ series: [], labels: [], original: { series: [], labels: [] }, disabledIndexes: [] });
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [currency, setCurrency] = useState("All");
	const [allGames, setAllGames] = useState([]);
	const [selectedGame, setSelectedGame] = useState("");

	useEffect(() => {
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		axios.get(`https://api.playerreporting.com/games/list?token=${casino?.casino_token}`).then((response) => {
			setAllGames(response.data?.data);
		});
	}, []);

	const getData = () => {
		const allCasinos = JSON.parse(localStorage.getItem("userInformation"));
		const brands = Object.groupBy(allCasinos?.casino_information, (casino) => casino.brand);

		if (!selectedGame) {
			setErrorMessage(SELECT_GAME);
			return;
		}

		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		setLoading(true);
		setErrorMessage("");

		axios
			.post(
				`https://api.playerreporting.com/reports/transactions/dashboard/ggr-brands-by-game?token=${casino?.casino_token}&casino=${casino?.casino_key}&currency=${
					currency === "All" ? "" : currency
				}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&gameId=${selectedGame.id}`,
				{ brands }
			)
			.then((response) => {
				if (response.data.status === 200) {
					setChartdata(response.data?.reportForChartGGR);
				} else {
					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setChartdata([]);
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setErrorMessage("");
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle, currency, selectedGame]);

	useEffect(() => {
		console.log(chartdata);

		if (Object.keys(chartdata).length) {
			const gameTypeGGRs = Object.keys(chartdata).map((gameType) => parseFloat(chartdata[gameType]?.GGR.toFixed(2)));
			setErrorMessage("");
			const modifiedData = { series: gameTypeGGRs, labels: Object.keys(chartdata) };
			setOptionsData((p) => ({ ...p, ...modifiedData, original: modifiedData }));
		} else {
			const emptyData = { series: [], labels: [] };
			setOptionsData((p) => ({ ...p, ...emptyData, original: { ...emptyData } }));
			if (selectedGame) {
				setErrorMessage("There are no records to display");
			}
		}
	}, [chartdata]);

	const toggleData = (index) => {
		setOptionsData((prev) => {
			if (!prev.disabledIndexes.includes(index)) {
				const disabledIndexes = [...prev.disabledIndexes, index];
				const newSeries = prev.original.series.filter((series, sIndex) => !disabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => !disabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: disabledIndexes };
			} else {
				let updatedDisabledIndexes = [...prev.disabledIndexes];

				updatedDisabledIndexes = updatedDisabledIndexes.filter((el) => el !== index);

				const newSeries = prev.original.series.filter((series, sIndex) => !updatedDisabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => !updatedDisabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: updatedDisabledIndexes };
			}
		});
	};

	const chartProps = {
		series: optionsData.series,
		options: {
			chart: {
				width: "100%",
				type: "pie"
			},
			labels: optionsData.labels,
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex];
					return [name, parseFloat(val).toFixed(1) + "%"];
				}
			},
			legend: {
				horizontalAlign: "left",
				offsetX: 20,
				labels: {
					colors: "#fff"
				},
				markers: {
					offsetX: -3,
					offsetY: 2
				},
				itemMargin: {
					horizontal: 10
				}
			},
			responsive: [
				{
					breakpoint: 575,
					options: {
						legend: {
							position: "bottom"
						}
					}
				}
			]
		}
	};

	return (
		<>
			<div className="finance_heapchart game_reports_charts mb_30">
				<Row className="align-items-center">
					<h5 className="h5_title">Brands GGR by Game</h5>
				</Row>
				<Row>
					<Col lg={4}></Col>
					<Col lg={4}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={22} />
								</div>
								Select Game
							</label>
							<DropDown
								placeholder="Select Game"
								options={allGames}
								dataKey="game"
								isSelected={selectedGame?.game}
								activeOption={selectedGame?.game}
								activeOptionId={selectedGame?.game}
								setOption={(data, index) => {
									setSelectedGame(data);
								}}
							/>
						</div>
					</Col>
					<Col lg={4}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
					</Col>
				</Row>
			</div>

			{!loading && errorMessage ? (
				<p style={{ textAlign: "center", ...(errorMessage !== SELECT_GAME && { color: "red" }) }}>{errorMessage}</p>
			) : (
				<>
					<Chart
						{...chartProps}
						type="pie"
						width="100%"
						height="500px"
					/>
					<div className="chart_options">
						{optionsData.original.labels?.map((label, index) => {
							const disabledLabel = optionsData.disabledIndexes.includes(index);
							return (
								<p
									onClick={() => toggleData(index)}
									className={disabledLabel ? "active_chart_option" : ""}
								>
									{label}
								</p>
							);
						})}
					</div>
				</>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default BrandsGGRbyGameChart;
