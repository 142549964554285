import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import moment from "moment";
import { BsCalendar2Date } from "react-icons/bs";
import Loader from "../../../components/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { IoGameControllerOutline } from "react-icons/io5";
import InputField from "../../../components/Common/InputField";

const PlayerChart = () => {
    const [state, setState] = useState({
        start: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
    });
    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
    };
    // const label = start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");

    const [chartdata, setChartdata] = useState();
    const [monthlist, setMonthlist] = useState([]);
    const [betsWon, setBetsWon] = useState([]);
    const [betsLost, setBetsLost] = useState([]);
    const [betsplaced, setBetsplaced] = useState([]);
    const [loading, setLoading] = useState(false);
    const [gameId, setGameId] = useState();
    const [errorMessage, setErrorMessage] = useState("");

    // play report chart display

    const chartData = async (startDate, endDate, game_Id) => {
        const currentCasino = JSON.parse(localStorage?.getItem("currentCasino"));
        setLoading(true);
        await axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/reports/transactions/chart?token=${
                    currentCasino?.casino_token
                }&casino=${currentCasino?.casino_key}&start=${moment(start).format(
                    "YYYY-MM-DD"
                )}T00:00:00Z&end=${moment(end).format("YYYY-MM-DD")}T23:00:00Z&game_id=${game_Id || ""}`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    setChartdata(response.data.response);
                } else {
                    setErrorMessage(response?.data?.message);
                }
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    setErrorMessage(error.response.statusText);
                }
                setErrorMessage(error.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        const startDateApi = moment().startOf("month").format("YYYY-MM-DD");
        const endDateApi = moment().endOf("month").format("YYYY-MM-DD");
        chartData(startDateApi, endDateApi);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitCartData = (e) => {
        e.preventDefault();
        setErrorMessage("");
        setLoading(true);
        const startDateApi = moment().startOf("month").format("YYYY-MM-DD");
        const endDateApi = moment().endOf("month").format("YYYY-MM-DD");
        chartData(startDateApi, endDateApi, gameId);
    };

    useEffect(() => {
        var monthllistarr = [];
        var betwonarr = [];
        var betlostarr = [];
        var brtplacedarr = [];
        if (chartdata) {
            // eslint-disable-next-line array-callback-return
            chartdata.map((val, i) => {
                var cudate = val.date;
                monthllistarr.push(cudate);
                betwonarr.push(
                    val.totalDebit.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })
                );
                betlostarr.push(
                    val.totalCredit.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })
                );
                brtplacedarr.push(
                    val.totalBet.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })
                );
            });

            if (!monthllistarr.length && !betwonarr.length && !betlostarr.length && !brtplacedarr.length) {
                setMonthlist([]);
                setBetsWon([]);
                setBetsLost([]);
                setBetsplaced([]);
                setErrorMessage("There are no records to display");
            } else {
                setErrorMessage("");
                setMonthlist(monthllistarr);
                setBetsWon(betwonarr);
                setBetsLost(betlostarr);
                setBetsplaced(brtplacedarr);
            }
            // setLoading(false);
        }
    }, [chartdata]);

    const playerChartoption = {
        options: {
            chart: {
                height: 350,
                toolbar: {
                    show: false,
                },
            },

            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: [1, 1, 4],
            },
            xaxis: {
                categories: monthlist,
                labels: {
                    style: {
                        colors: "#008FFB",
                    },
                },
            },
            yaxis: [
                {
                    padding: {
                        left: 0,
                        right: 0,
                    },
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        // offsetX: -12,
                        show: true,
                        color: "#008FFB",
                    },
                    labels: {
                        formatter: function (value) {
                            return (
                                value.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }) + " USD"
                            );
                        },
                        style: {
                            colors: "#008FFB",
                        },
                    },
                    title: {
                        text: "Total Won and Lost",
                        style: {
                            color: "#008FFB",
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                {
                    seriesName: "Bets Lost",
                    opposite: true,
                    labels: {
                        formatter: function (value) {
                            return (
                                value.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }) + " USD"
                            );
                        },
                        show: false,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                {
                    seriesName: "Total Bets Placed",
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: "#FEB019",
                    },
                    labels: {
                        style: {
                            colors: "#FEB019",
                        },
                    },
                    title: {
                        text: "Total Bets Placed",
                        style: {
                            color: "#FEB019",
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
            ],
            tooltip: {
                fixed: {
                    enabled: true,
                    position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60,
                },
                theme: "dark",
            },
            legend: {
                horizontalAlign: "left",
                offsetX: 20,
                labels: {
                    colors: "#fff",
                },
                markers: {
                    offsetX: -3,
                    offsetY: 2,
                },
                itemMargin: {
                    horizontal: 10,
                },
            },
            // tooltip: {
            //   theme: "dark",
            //   followCursor: true,
            // },
            grid: {
                padding: {
                    left: 0,
                    right: 0,
                },

                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        },
        series: [
            {
                name: "Bets Won",
                type: "column",
                data: betsWon,
            },
            {
                name: "Bets Lost",
                type: "column",
                data: betsLost,
            },
            {
                name: "Total Bets Placed",
                type: "line",
                data: betsplaced,
            },
        ],
    };

    return (
        <div className="form_box_wp mb_30">
            <div className="player_chart table_loader_wp">
                <form onSubmit={onSubmitCartData}>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form_input_wp">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <BsCalendar2Date size={20} />
                                    </div>
                                    Select Date
                                </label>

                                <DateRangePicker
                                    initialSettings={{
                                        startDate: moment().startOf("month").toDate(),
                                        endDate: moment().endOf("month").toDate(),
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, "days").toDate(),
                                                moment().subtract(1, "days").toDate(),
                                            ],
                                            "Last 7 Days": [
                                                moment().subtract(6, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "Last 30 Days": [
                                                moment().subtract(29, "days").toDate(),
                                                moment().toDate(),
                                            ],
                                            "This Month": [
                                                moment().startOf("month").toDate(),
                                                moment().endOf("month").toDate(),
                                            ],
                                            "Last Month": [
                                                moment().subtract(1, "month").startOf("month").toDate(),
                                                moment().subtract(1, "month").endOf("month").toDate(),
                                            ],
                                        },
                                    }}
                                    onCallback={handleCallback}>
                                    <input type="text" className="form_input" />
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <InputField
                                type="text"
                                label="GameId"
                                labelIcon={<IoGameControllerOutline size={20} />}
                                placeholder="Enter GameId"
                                name="game_Id"
                                onChange={(event) => setGameId(event.target.value)}
                                value={gameId || gameId}
                            />
                        </div>
                        <div className="col-lg-3">
                            <button type="submit" className="sec_btn">
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
                <h5 className="h5_title text-center">Players</h5>
                {errorMessage ? (
                    <p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
                ) : (
                    <Chart {...playerChartoption} type="bar" width="100%" height="500px" />
                )}
                {loading ? <Loader /> : ""}
            </div>
        </div>
    );
};

export default PlayerChart;
