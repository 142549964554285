import { useState } from "react";
import { GiCreditsCurrency } from "react-icons/gi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function CurrenciesDropdown({ currencies, currency, setCurrency, otherData = -1, width }) {
    const [dropDown, setDropdown] = useState(false);
    return (
        <div className="datepicker_right">
            <label className="form_input_label">
                <div className="form_input_icon">
                    <GiCreditsCurrency size={20} />
                </div>
                Select Currency
            </label>
            <div className={`dropdown_wp currency_dropdown ${dropDown ? "active_dropdown" : ""}`}>
                <button
                    className="sec_btn dropdown_btn transparent_btn"
                    onClick={() => width <= 991 && setDropdown((prev) => !prev)}
                    onMouseEnter={() => width >= 991 && setDropdown(true)}
                    onMouseLeave={() => width >= 991 && setDropdown(false)}>
                    {currency}
                    {otherData !== -1 ? ` (${otherData})` : ""}
                    <span className="dropdown_icon">
                        <MdOutlineKeyboardArrowDown size={22} />
                    </span>
                </button>

                <ul className="dropdown_list">
                    {currencies?.map((currency) => (
                        <li
                            key={currency.currency_abrv}
                            onClick={() => {
                                setCurrency(currency.currency_abrv);
                            }}>
                            {currency?.currency_abrv}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default CurrenciesDropdown;
