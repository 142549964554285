import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { GiCreditsCurrency } from "react-icons/gi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import DetailedGameReports from "./DetailedGameReports";
import DropDown from "../../Common/DropDown";

const defaultGame = { id: 0, game: "All" };

const FinancialGameReports = ({ startDate, endDate, currency, refreshToggle, width, accessType }) => {
    const [allGames, setAllGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState(defaultGame);
    const [gameDropDown, setGameDropdown] = useState(false);

    /**
     * Games List
     */
    useEffect(() => {
        const casino = JSON.parse(localStorage?.getItem("currentCasino"));
        axios
            .get(`https://api.playerreporting.com/games/list?token=${casino?.casino_token}`)
            .then((response) => {
                setAllGames([defaultGame, ...response.data?.data]);
            });
    }, []);

    return (
        <>
            <Row>
                <Col lg={9}>
                    <div>
                        <h5 className="h5_title">Game Reports</h5>
                    </div>
                </Col>
                <Col lg={3}>
                    <>
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                    <GiCreditsCurrency size={20} />
                                </div>
                                Select Game
                            </label>
                            <DropDown
                                placeholder="Select Game"
                                options={allGames}
                                dataKey="game"
                                isSelected={selectedGame?.game}
                                activeOption={selectedGame?.game}
                                activeOptionId={selectedGame?.game}
                                setOption={(data, index) => {
                                    setSelectedGame(data);
                                }}
                            />
                            {/* <div
                                className={`dropdown_wp currency_dropdown ${gameDropDown ? "active_dropdown" : ""
                                    }`}>
                                <button
                                    className="sec_btn dropdown_btn transparent_btn"
                                    onClick={() => width <= 991 && setGameDropdown((prev) => !prev)}
                                    onMouseEnter={() => width >= 991 && setGameDropdown(true)}
                                    onMouseLeave={() => width >= 991 && setGameDropdown(false)}>
                                    {selectedGame?.game}
                                    <span className="dropdown_icon">
                                        <MdOutlineKeyboardArrowDown size={22} />
                                    </span>
                                </button>

                                <ul className="dropdown_list">
                                    {allGames?.map((currency) => (
                                        <li
                                            key={currency.id}
                                            onClick={() => {
                                                setSelectedGame(currency);
                                            }}>
                                            {currency?.game}
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    </>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <div className="my_20">
                        <DetailedGameReports
                            selectedGame={selectedGame}
                            startDate={startDate}
                            endDate={endDate}
                            currency={currency}
                            accessType={accessType}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default FinancialGameReports;
